import { LOGIN_USER, REMOVE_USER, ADD_USER, CREATE_ORDER_DATA } from "../types";

const loginUser = (user) => {
  return {
    type: LOGIN_USER,
    user,
  };
};

const removeUser = (user) => {
  return {
    type: REMOVE_USER,
    user: user,
  };
};
const AddUser = (user) => {
  return {
    type: ADD_USER,
    user: user,
  };
};

const createOrderData = (data) => {
  console.log("CREATE_ORDER_DATA", data);
  return {
    type: CREATE_ORDER_DATA,
    data,
  };
};

export { loginUser, removeUser, AddUser, createOrderData };
