import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import allPaths from "../../Config/paths";
import axios from "axios";
import { useDispatch } from "react-redux";
import { AddUser } from "../../Redux/actions/authActions";
import { AUTH } from "../../utils/apis";
import { Button, GoogleLoginBtn } from "../../Components/index";
import jsonp from "jsonp";
import GoogleTag from "../../Components/GoogleAnalytics/googleTag";

import {
  requiredMessage,
  inputPlace,
  successMessage,
  errorMessage,
  warningMessage,
} from "../../utils/helpers";
import { ADD_USER } from "../../Redux/types";

const Signup = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked2, setIsChecked2] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();

  const validateEmail = (e) => {
    return String(e)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  };

  const handleCheckboxRequired = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleCheckboxMarketing = (e) => {
    setIsChecked2(e.target.checked);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!fullName || !password || !email || !isChecked) {
      errorMessage("Please provide all fields and accept the privacy policy.");
      return true;
    }

    if (password.length < 8) {
      errorMessage("Your password must be at least 8 characters.");
      return true;
    }

    if (!validateEmail(email)) {
      return warningMessage("Please input a valid email address.");
    }

    setLoading(true);
    const formData = {
      name: fullName,
      email,
      password,
    };

    try {
      const url =
        "https://gmail.us21.list-manage.com/subscribe/post-json?u=b98cca37d6369cfa31ec27c77&amp;id=f98c55ae35&amp;f_id=00cfade1f0";
      jsonp(`${url}&EMAIL=${email}`, { param: "c" }, (_, data) => {
        const { msg, result } = data;
        // do something with response
        alert(msg);
      });

      const res = await axios.post(AUTH.SIGNUP, formData);
      const { data } = res;

      if (data.success) {
        setLoading(false);
        successMessage("Successfully signed up!");
        setTimeout(() => {
          history.push(allPaths.LOGIN);
        }, 300);
      } else {
        errorMessage(data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <div className="signup_section">
        <GoogleTag/>
        <div className="w-users-userformpagewrap">
          <div className="w-users-usersignupformwrapper">
            <div
              tabIndex={-1}
              className="w-users-userformsuccessstate w-form-success"
            >
              <div className="w-users-userformheader">
                <img
                  src="https://d3e54v103j8qbb.cloudfront.net/img/thumbs-up-72.cbcaec93bc.svg"
                  alt=""
                />
                <h2>Account activated</h2>
              </div>
              <p>
                Your account was created successfully. You will be redirected
                shortly.
              </p>
              <div data-wf-user-form-redirect="">
                <a href="index.html">If nothing happens, click here.</a>
              </div>
            </div>

            <form method="post" data-wf-user-form-type="signup">
              <div
                className="w-users-userformheader"
                style={{ display: "flex", alignItems: "center" }}
              >
                <button
                  onClick={() => history.goBack()}
                  style={{
                    marginRight: "15px",
                    backgroundColor: "transparent",
                    border: "none",
                    color: "white",
                  }}
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="white">
                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
                  </svg>
                </button>
                <h2 className="login_signup_heading">Sign up</h2>
              </div>

              <label htmlFor="Email">Email</label>
              <div className="form-group">
                <div className="relative">
                  <input
                    className="form-control user_input"
                    id="name"
                    data-wf-user-form-input-type="email"
                    type="email"
                    required
                    autoFocus=""
                    autoComplete=""
                    placeholder="Type your Email here..."
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    name="name"
                  />
                  <i className="fa fa-user" />
                </div>
              </div>
              <br />
              <label htmlFor="Name">Name</label>
              <div className="form-group">
                <div className="relative">
                  <input
                    className="form-control user_input"
                    id="name"
                    data-wf-user-form-input-type="wf-user-field-name"
                    type="text"
                    required
                    autoFocus=""
                    autoComplete=""
                    placeholder="Type your Full Name here..."
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    name="name"
                  />
                  <i className="fa fa-user" />
                </div>
              </div>
              <br />
              <label htmlFor="Password">Password</label>
              <div className="form-group">
                <div className="relative">
                  <input
                    className="form-control user_input"
                    id="name"
                    data-wf-user-form-input-type="password"
                    type="password"
                    required
                    autoFocus=""
                    autoComplete=""
                    placeholder="Type your Password here..."
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    name="name"
                  />
                  <i className="fa fa-user" />
                </div>
              </div>
              <br />

              <label className="w-checkbox">
                <input
                  type="checkbox"
                  id="wf-sign-up-accept-privacy"
                  required="*"
                  name="Checkbox-required"
                  data-name="Checkbox"
                  data-wf-user-field="wf-user-field-accept-privacy"
                  className="w-checkbox-input"
                  checked={isChecked} // Controlled component
                  onChange={(e) => {
                    setIsChecked(e.target.checked);
                  }}
                />
                <span className="w-form-label" htmlFor="Checkbox">
                  By creating an account, you agree to this website's{" "}
                  <a
                    style={{ color: "#d6fc52" }}
                    onClick={() => history.push(allPaths.PRIVACYPOLICY)}
                  >
                    privacy policy
                  </a>{" "}
                  and{" "}
                  <a onClick={() => history.push(allPaths.TERMSCONDITIONS)}>
                    terms of service
                  </a>
                </span>
              </label>

              <label className="w-checkbox">
                <input
                  type="checkbox"
                  id="wf-sign-up-accept-communications"
                  required=""
                  name="Checkbox-marketing"
                  data-name="Checkbox"
                  data-wf-user-field="wf-user-field-accept-communications"
                  className="w-checkbox-input"
                  checked={isChecked2} // Controlled component
                  onChange={(e) => {
                    setIsChecked2(e.target.checked);
                  }}
                />
                <span className="w-form-label" htmlFor="Checkbox">
                  I consent to receive marketing emails.
                </span>
              </label>

              <div className="w-users-userformfooter">
                <span>Already have an account?</span>
                <a
                  className="font-size-18"
                  style={{ fontSize: 18 }}
                  onClick={() => history.push(allPaths.LOGIN)}
                >
                  Log In
                </a>
              </div>

              <Button
                className="w-users-userformbutton signup_login_button"
                onClick={handleSubmit}
                loading={loading}
                Name="Sign Up"
                disabled={!isChecked}
              />
              <p className="or">OR</p>
              <div style={{ marginBottom: 15 }}>
                <GoogleLoginBtn
                  btnText="Sign up with google"
                  isSignup={true}
                  disabled={!isChecked}
                />
              </div>
            </form>

            <div
              style={{ display: "none" }}
              data-wf-user-form-error="true"
              className="w-users-userformerrorstate w-form-fail"
            >
              <div
                className="user-form-error-msg"
                wf-signup-form-general-error-error="There was an error signing you up. Please try again, or contact us if you continue to have problems."
                wf-signup-form-not-allowed-error="You're not allowed to access this site, please contact the admin for support."
                wf-signup-form-invalid-email-error="Make sure your email exists and is properly formatted (e.g., user@domain.com)."
                wf-signup-form-email-already_exist-error="An account with this email address already exists. Log in or reset your password."
                wf-signup-form-use-invite_email-error="Use the same email address your invitation was sent to."
                wf-signup-form-invalid-password-error="Your password must be at least 8 characters."
                wf-signup-form-not-verified-error="We couldn't verify your account. Please try again, or contact us if you continue to have problems."
                wf-signup-form-expired-token-error="This verification link has expired. A new verification link has been sent to your email. Please try again, or contact us if you continue to have problems."
                wf-signup-form-validation-failed-error="There was an error in some of the information provided."
              >
                There was an error signing you up. Please try again, or contact
                us if you continue to have problems.
              </div>
            </div>
            <div
              tabIndex={-1}
              className="w-users-usersignupverificationmessage w-form-verification"
              data-wf-user-form-verification="true"
            >
              <div className="w-users-userformheader">
                <img
                  src="https://d3e54v103j8qbb.cloudfront.net/img/email-72.67fa6be437.svg"
                  alt=""
                />
                <h2>Verification Required</h2>
              </div>
              <p>
                Account verification required. Please check your email to find
                your unique verification link.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
