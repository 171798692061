import React, { useState, useEffect } from "react";
import circleImage1 from "../../assets/Images/Circle-Design-01_1Circle Design 01.webp";
import circleImage2 from "../../assets/Images/Circle-Design-02_1Circle Design 02.webp";
import circleImage3 from "../../assets/Images/Circle-Design-03_1Circle Design 03.webp";
import about1 from "../../assets/Images/About-Image-01_1About Image 01.webp";
import about2 from "../../assets/Images/About-Image-02_1About Image 02.webp";
import AOS from "aos";

AOS.init({});

const AboutUs = () => {
  return (
    <div>
      <div
        data-w-id="604edd17-092b-35df-a4bf-168a7d79ece0"
        className="section white-section"
      >
        <div id="Art" className="container white-container">
          <div className="relative">
            <div className="center-text">
              <div className="art-title-holder">
                <div className="fade-in-move-on-scroll">
                  <h1 className="title-clean" style={{ color: "#d6fc52" }}>
                    You can make one hoodie for your friend, or print hundreds
                    for your business
                  </h1>
                  <h1 className="title-clean dimmed" style={{ color: "white" }}>
                    {" "}
                    The power is in your hands, because we know you’re the
                    fashion designer of the century.
                  </h1>
                </div>
              </div>
            </div>
            <div className="generate-section-holder">
              <div className="generate-section">
                <div
                  className="generate-section-text-holder"
                  data-aos="fade-right"
                >
                  <div className="generate-section-text-container">
                    <div className="generate-title">
                      <div className="purple-text">/Generate:</div>
                    </div>
                    <div className="generate-title-holder">
                      <div
                        className="generate-main-text"
                        style={{ color: "#d6fc52" }}
                      >
                        Artificial Intelligence (AI) Image Generation
                      </div>
                    </div>
                    <div
                      className="generate-description-holder"
                      style={{ color: "white" }}
                    >
                      Our specialist artificial intelligence software turns all
                      of those incredible ideas, artworks and designs knocking
                      around in your head into mind-blowing art. All you have to
                      do is type the words, we’ll make it real.
                    </div>
                  </div>
                </div>
                <div
                  className="generate-section-image-holder"
                  data-aos="fade-left"
                >
                  <img
                    src={circleImage1}
                    loading="lazy"
                    alt=""
                    className="generate-image"
                  />
                </div>
              </div>
              <div className="generate-section">
                <div
                  id="w-node-d8993a6a-20e3-6f04-daf8-3b82a2b9e258-e80fff32"
                  className="generate-section-image-holder"
                >
                  <img
                    src={circleImage2}
                    loading="lazy"
                    alt=""
                    className="generate-image"
                  />
                </div>
                <div
                  className="generate-section-text-holder"
                  data-aos="fade-left"
                >
                  <div className="generate-section-text-container">
                    <div className="generate-title">
                      <div className="yellow-text">/Design:</div>
                    </div>
                    <div className="generate-title-holder">
                      <div
                        className="generate-main-text"
                        style={{ color: "#d6fc52" }}
                      >
                        Clothing Design
                      </div>
                    </div>
                    <div
                      className="generate-description-holder"
                      style={{ color: "white" }}
                    >
                      As soon as you've made your artwork, we can turn it into
                      clothing for you. Our huge range of unisex clothing
                      options ensure you can create unique designs. Your
                      fashion. Your style. Your creativity. Found nowhere else.
                    </div>
                  </div>
                </div>
              </div>
              <div className="generate-section">
                <div
                  className="generate-section-text-holder"
                  data-aos="fade-right"
                >
                  <div className="generate-section-text-container">
                    <div className="generate-title">
                      <div className="purple-text red">/Community:</div>
                    </div>
                    <div className="generate-title-holder">
                      <div
                        className="generate-main-text"
                        style={{ color: "#d6fc52" }}
                      >
                        Our Community (coming soon)
                      </div>
                    </div>
                    <div
                      className="generate-description-holder"
                      style={{ color: "white" }}
                    >
                      Aipperal is quickly growing a huge community - ranging
                      from artists, designers, businesses, freelancers or just
                      your average Joe. We pride ourselves on our creative hub
                      of opportunity, if you want to hear more from us and get
                      involved, join our newsletter.
                    </div>
                  </div>
                </div>
                <div className="generate-section-image-holder">
                  <img
                    src={circleImage3}
                    loading="lazy"
                    alt=""
                    className="generate-image"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="white-background" />
        </div>
        <div className="yellow-background" />
      </div>
      <div className="section white-background-section">
        <div id="About" className="about-div" />
      </div>
    </div>
  );
};

export default AboutUs;
