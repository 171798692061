import React from "react";
import { Header, Footer } from "../../Components";
import ImageForm from "./ImageForm";

import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import allPaths from "../../Config/paths";
import GoogleTag from "../../Components/GoogleAnalytics/googleTag";

function Build() {
  const user = useSelector((state) => state.authReducer.user);
  if (!user) return <Redirect to={allPaths.LOGIN} />;

  return (
    <div className="build_Section">
      <GoogleTag/>
      <Helmet>
        <title>Custom Fashion Design | Create Your Own Attire Online</title>
        <meta
          name="description"
          content="Unleash your creativity on our custom fashion design platform. Use our AI-powered design tools to create unique attire that reflects your style and personality."
        />
        <meta
          name="keywords"
          content="Custom Fashion Design, Online Clothing Design, AI Design Tools, Personalized Attire"
        />
      </Helmet>

      <Header />
      <div className="section">
        <div className="container">
          <ImageForm user={user} />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Build;
