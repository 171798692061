import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import allPaths from "../../Config/paths";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { AUTH, GET } from "../../utils/apis";
import { useDispatch } from "react-redux";
import { AddUser } from "../../Redux/actions/authActions";
import { Uploader, Header, Footer } from "../../Components";
import {
  requiredMessage,
  inputPlace,
  successMessage,
  errorMessage,
  warningMessage,
} from "../../utils/helpers";
import { Table, Tabs, Tag } from "antd";
import moment from "moment";
import Stripe from "../../Components/Stripe/Stripe";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const UserAccount = () => {
  const user = useSelector((state) => state.authReducer.user);
  const Name = user && user.name;
  const Email = user && user.email;
  const UserID = user && user._id;
  const Address = user && user.address;
  const TopUp = "TopUp";
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  // const [fileList, setFileList] = useState([])
  // const [myOrders, setMyOrders] = useState([])
  // const [myOrdersLoading, setMyOrdersLoading] = useState(false)
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState(Name);
  const [email, setEmail] = useState(Email);
  const [address, setAddress] = useState(Address);
  const [amount, setAmount] = useState(7.88);

  // const getMyOrders = () => {
  //     if (user) {
  //         setMyOrdersLoading(true)
  //         axios.get(`${GET.GET_MY_ORDERS}/${user._id}`)
  //             .then((res) => {
  //                 const { data } = res
  //                 if (data.success) {
  //                     setMyOrders(data.data);
  //                 }
  //                 else {
  //                     errorMessage(data.message)
  //                 }
  //                 setMyOrdersLoading(false)
  //             })
  //             .catch((e) => {
  //                 setMyOrdersLoading(false)
  //                 console.log(e)
  //             })
  //     }
  // }

  useEffect(() => {
    // getMyOrders();

    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get("status");
    const session = queryParams.get("session");

    const newUrl = `${window.location.pathname}`;
    window.history.replaceState(null, null, newUrl);

    let Payment;
    let paymentType;

    if (status === "Failed") {
      errorMessage("Payment Failed, please try again.");
    } else if (status === "Passed") {
      if (session) {
        const sessionParts = session.split(",");
        paymentType = sessionParts[0];
        Payment = parseInt(sessionParts[1]) / 100;
        if (paymentType === TopUp) {
          user.topUp += parseInt(sessionParts[2]);
          handleSubmit(null);
          successMessage(
            `Payment successful, for ${paymentType} of £${Payment}`,
          );
        }
      }
    }
  }, [location]);

  // if (!user) return <Redirect to={allPaths.LOGIN} />

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    let formData = {
      name: name,
      email: email,
      userId: UserID,
      address: address,
      topUp: user.topUp,
    };
    setLoading(true);

    axios
      .post(AUTH.UPDATEUSER, formData)
      .then((res) => {
        const { data } = res;
        if (data.success) {
          successMessage("Profile Updated Successfully");
          dispatch(AddUser(data.user));
        } else {
          errorMessage(data.message);
        }
        setLoading(false);
      })
      .catch((e) => {
        // errorMessage()
        setLoading(false);
        console.log(e);
      });
  };

  const columns = [
    {
      title: "Order Id",
      dataIndex: ["order", "id"],
    },
    {
      title: "Product Name",
      dataIndex: ["order", "items"],
      render: (e) => e[0].sku,
    },
    {
      title: "Quantity",
      dataIndex: ["order", "items"],
      render: (e) => e[0].copies,
    },
    {
      title: "Shipping Method",
      dataIndex: ["order", "shippingMethod"],
    },
    {
      title: "Charges",
      dataIndex: ["price"],
      render: (charge, record) => {
        const { order } = record;
        let countryCode = order?.recipient?.address?.countryCode;
        let currencyUnit =
          countryCode && countryCode == "GB"
            ? "£"
            : countryCode && countryCode == "US"
            ? "$"
            : "A$";
        return charge ? `${currencyUnit} ${Number(charge)?.toFixed(2)}` : "--";
      },
    },
    {
      title: "Status",
      dataIndex: ["order", "status"],
      render: (e) =>
        e.stage == "Complete" ? (
          <Tag color="green">Complete</Tag>
        ) : (
          <Tag color="orange">InProgress</Tag>
        ),
    },

    {
      title: "Created",
      dataIndex: ["order", "created"],
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
  ];

  return (
    <div className="build_Section">
      <div>
        <Header />
      </div>
      <div className="">
        <div className="main_user_account">
          <form className="form " method="post" onSubmit={handleSubmit}>
            <div className="heading_div">
              <h2>Your Profile:</h2>
            </div>
            <div className="form-group">
              <label htmlFor="email">Name:</label>
              <div className="relative">
                <input
                  className="form-control user_input"
                  id="name"
                  type="text"
                  required
                  autoFocus=""
                  title="Username should only contain letters."
                  autoComplete=""
                  placeholder="Type your name here..."
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  name="name"
                />
                <i className="fa fa-user" />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <div className="relative">
                <input
                  name="email"
                  className="form-control"
                  type="email"
                  required=""
                  placeholder="Type your email address..."
                  //  onChange={handleEmailChange}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <i className="fa fa-envelope" />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="email">Address:</label>
              <div className="relative">
                <input
                  className="form-control"
                  type="address"
                  name="address"
                  required=""
                  placeholder="Type your Address..."
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
                <i className="fa fa-phone" />
              </div>
            </div>

            {user.socialId ? null : (
              <div className="reset_password">
                <h3>Password settings</h3>
                <a onClick={() => history.push(allPaths.UPDATEPASSWORD)}>
                  {" "}
                  Update password{" "}
                </a>
              </div>
            )}

            <div className="userAccount_button">
              {loading ? (
                <button className="save_and_cancel savechanges" type="submit">
                  {loading && <i className="fa fa-spinner fa-spin"></i>} &nbsp;
                  Save Changes{" "}
                </button>
              ) : (
                <button className="save_and_cancel savechanges" type="submit">
                  Save Changes{" "}
                </button>
              )}

              <span className="cancel">Cancel</span>
            </div>
            <h3>
              {" "}
              Your remaining balance is: {user.topUp > 0 ? user.topUp : 0}
            </h3>
          </form>
          <br />
          <div style={{ display: "flex" }}>
            <Stripe amount={amount} paymentType={TopUp} />
            <select
              style={{
                fontSize: "150px",
                maxHeight: "200px",
                maxWidth: "200px",
                overflow: "auto",
                borderRadius: "10px",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingLeft: "5px",
              }}
              onClick={(e) => setAmount(Number(e.target.value))}
            >
              <option value="7.88">150 (£7.88)</option>
              <option value="14.88">320 (£14.88)</option>
              <option value="21.88">490 (£21.88)</option>
              <option value="28.88">660 (£28.88)</option>
            </select>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default UserAccount;
