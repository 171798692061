import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AUTH } from "../../utils/apis";
import { useDispatch } from "react-redux";
import { AddUser } from "../../Redux/actions/authActions";
import { successMessage, errorMessage, googleLogin } from "../../utils/helpers";
// import { GoogleLogin } from 'react-google-login'
import google_logo from "../../assets/Images/google_logo.png";
import { gapi } from "gapi-script";
import {
  GoogleLogin,
  hasGrantedAnyScopeGoogle,
  useGoogleLogin,
} from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import jsonp from "jsonp";


const GoogleLoginBtn = ({ btnText, isSignup }) => {
  const history = useHistory();
  const [spin, setSpin] = useState(false);
  // const [cred, setCred] = useState({})
  const dispatch = useDispatch();
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId:
          "244276883148-bu4mnocfksnu6ohjrenq755ufrurjjc2.apps.googleusercontent.com",
        scope: "email",
      });
    }

    gapi.load("client:auth2", start);
  }, []);

  const updateGoogleLogin = async (credentialResponse) => {
    setSpin(true);
    if (credentialResponse.credential != null) {
      const USER_CREDENTIAL = jwtDecode(credentialResponse.credential);
      //console.log(USER_CREDENTIAL);
      console.log("issignup: ", isSignup);
      if(isSignup === true){
        const url =
          "https://gmail.us21.list-manage.com/subscribe/post-json?u=b98cca37d6369cfa31ec27c77&amp;id=f98c55ae35&amp;f_id=00cfade1f0";
        jsonp(`${url}&EMAIL=${USER_CREDENTIAL.email}`, { param: "c" }, (_, data) => {
          const { msg, result } = data;
          successMessage(msg);
        });
      }

      const success = await googleLogin(
        USER_CREDENTIAL,
        history,
        AddUser,
        dispatch,
        isSignup,
      );

      if (!success) {
        setSpin(false);
      }
    } else {
      return errorMessage("Google Login Error...!");
    }
    //console.log("credentialResponse", credentialResponse);
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      console.log("tokenResponse", tokenResponse);
      return updateGoogleLogin(tokenResponse);
    },
  });
  return (
    <>
      <GoogleLogin
        onSuccess={updateGoogleLogin}
        onError={(err) => {
          console.log("Login Failed", err);
        }}
      />
    </>
  );
};

export default GoogleLoginBtn;
