import React from "react";
import { Footer, Header } from "../../Components";
import dog1 from "../../assets/Images/Card-Image-01_1Card Image 01.webp";
import card_icon1 from "../../assets/Images/Card-Icon-01.svg";
import card_image from "../../assets/Images/Card-Image-02_1Card Image 02.webp";
import logo1 from "../../assets/Images/Logo-01.svg";
import logo2 from "../../assets/Images/Logo-02.svg";
import logo3 from "../../assets/Images/Logo-03.svg";
import bg_img from "../../assets/Images/landing_page_bg.webp";
import fast_1 from "../../assets/Images/Fast_1Fast.webp";
import text_img from "../../assets/Images/Text-To-Imag_1Text To Imag.webp";
import { useHistory } from "react-router-dom";
import allPaths from "../../Config/paths";
import imageUnknown from "../../assets/Images/Card-Background-01_1Card Background 01.webp";
import imageUnknown2 from "../../assets/Images/Card-BG-Image-02_1Card BG Image 02.webp";
import imageUnknown3 from "../../assets/Images/CTA-BG_1CTA BG.webp";
import Animation from "./Animation";
import AboutUs from "./AboutUs";
import { useSelector } from "react-redux";
import AOS from "aos";
import { Helmet } from "react-helmet-async";
import ParticleBackground from "react-particle-backgrounds";
import GoogleTag from "../../Components/GoogleAnalytics/googleTag";

AOS.init({
  offset: 420,
  duration: 1000,
});
const Home = () => {
  const settings = {
    canvas: {
      canvasFillSpace: true,
      width: 200,
      height: 200,
      useBouncyWalls: false,
    },
    particle: {
      particleCount: 100,
      color: "#94ecbe",
      minSize: 2,
      maxSize: 5,
    },
    velocity: {
      directionAngle: 0,
      directionAngleVariance: 360,
      minSpeed: 1,
      maxSpeed: 3,
    },
    opacity: {
      minOpacity: 0,
      maxOpacity: 0.5,
      opacityTransitionTime: 3000,
    },
  };
  const user = useSelector((state) => state.authReducer.user);

  const history = useHistory();

  return (
    <div className="main_container">
      <GoogleTag/>
      <Helmet>
        <meta
          name="description"
          content="Discover a new way to express your fashion ideas through our AI clothing design platform. Perfect for designers, style enthusiasts, or anyone who loves to express themselves through clothing. Start creating your unique designs today."
        />
        <meta
          name="keywords"
          content="AI Clothing Design, Fashion Design, Unique Fashion Online, Express through Clothing, Fashion Ideas"
        />
      </Helmet>
      <Header />

      <div>
        <div className="section">
          {window.location.origin.includes("localhost") ? null : (
            <>
              <ParticleBackground settings={settings && settings} />
            </>
          )}

          <div className="container">
            <div className="hero-section-holder">
              <div className="hero-section-container">
                <div className="hero-section-title">
                  <div className="hero-text">
                    <h1 className="title-clean-heading">
                      Where AI Meets Apparel.
                    </h1>
                    <h1
                      className="title-clean-heading"
                      style={{ color: "white" }}
                    >
                      Artificially Intelligent Clothing, Designed by You.
                    </h1>
                  </div>
                </div>
                <div className="hero-section-paragraph-holder">
                  <h3 style={{ color: "white" }}>
                    With our one-of-a-kind artificial intelligence model, all
                    you have to do is describe your vision. We'll make you the
                    clothes.
                  </h3>
                </div>
                <div className="hero-section-button-holder">
                  <a
                    onClick={() => {
                      if (user?._id) {
                        history.push(allPaths.BUILD);
                      } else {
                        history.push(allPaths?.LOGIN);
                      }
                    }}
                    className="button big w-button  animate__animated  animate__bounce"
                  >
                    Try It out
                  </a>
                </div>
              </div>
            </div>
          </div>

          <Animation />
        </div>
        <div id="Drops" className="section overflow-hidden-mobile">
          <div className="container">
            <div className="drops-section-holder">
              <div className="center-text">
                <div className="title-holder">
                  <div className="fade-in-move-on-scroll">
                    <h1 className="title-clean" style={{ color: "white" }}>
                      How does it work?
                    </h1>
                  </div>
                  <div className="fade-in-move-on-scroll">
                    <h1 className="title-clean-heading">
                      An easy guide to Aipperal.
                    </h1>
                  </div>
                </div>
              </div>
              <div className="grey-cards-holder">
                <div
                  data-w-id="eaf7432c-fa80-bdc6-2db3-6199aa2326cb"
                  className="grey-cards-wrapper"
                >
                  <div
                    className="grey-cards-content-holder"
                    data-aos="fade-right"
                  >
                    <div className="grey-card-text-holder">
                      <div className="grey-card-icon-holder">
                        <img src={card_icon1} className="grey-card-icon" />
                      </div>
                      <div className="card-title-holder">
                        <h2 className="white-title">
                          AI-powered fashion design.
                        </h2>
                      </div>
                      <div className="card-paraghraph-holder">
                        <p className="card-paragraph">
                          You can tell Aipperal what you're thinking, and it
                          will generate unique artwork just for you. With total
                          ownership over your design, you can create any clothes
                          you want.
                        </p>
                      </div>
                      <div className="replaces-holder">
                        <ol role="list" className="list w-list-unstyled">
                          <li>
                            <div className="list-item-holder">
                              <div className="list-circle" />
                              <div className="list-text">Give a prompt</div>
                            </div>
                          </li>
                          <li>
                            <div className="list-item-holder">
                              <div className="list-circle" />
                              <div className="list-text">
                                Perfect your design
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="list-item-holder">
                              <div className="list-circle" />
                              <div className="list-text">
                                Show off your new outfit
                              </div>
                            </div>
                          </li>
                        </ol>
                      </div>
                    </div>
                    <div className="grey-card-image-holder">
                      <img src={dog1} className="grey-card-image" />
                    </div>
                  </div>
                  {/* IMG */}
                  <div className="background-decor-holder">
                    <img
                      src={imageUnknown}
                      loading="lazy"
                      sizes="(max-width: 479px) 94vw, (max-width: 767px) 93vw, (max-width: 991px) 94vw, (max-width: 1439px) 95vw, 1294px"
                      alt=""
                      className="background-decor"
                    />
                  </div>
                </div>

                <div className="container">
                  <div className="cta-holder">
                    <div className="cta-container">
                      <div className="cta-content-holder">
                        <div className="cta-title-holder">
                          <div className="fade-in-move-on-scroll">
                            <h1 className="title-clean">Try it Out</h1>
                          </div>
                          <div className="fade-in-move-on-scroll">
                            <h1 className="title-clean dimmed">
                              Describe your design
                            </h1>
                          </div>
                        </div>
                        <div className="cta-wrapper">
                          <div className="cta-input-holder">
                            <div className="purple-text">/Generate:</div>
                            <div className="text-auto-wrapper">
                              <div className="text-auto-switch-holder">
                                <div
                                  data-w-id="38262d42-4484-51ac-09f5-d3df3ad06d9b"
                                  className="text-auto-switch"
                                >
                                  <div className="text-auto">
                                    A old ship sailing through space
                                  </div>
                                </div>
                              </div>
                            </div>
                            <a
                              onClick={() => {
                                if (user?._id) {
                                  history.push(allPaths.BUILD);
                                } else {
                                  history.push(allPaths?.LOGIN);
                                }
                              }}
                              className="button full w-button"
                            >
                              Build
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="background-decor-holder">
                        <img
                          src={imageUnknown3}
                          loading="lazy"
                          sizes="(max-width: 479px) 94vw, (max-width: 767px) 93vw, (max-width: 991px) 94vw, (max-width: 1439px) 95vw, 1294px"
                          alt=""
                          className="background-decor"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="grey-cards-wrapper">
                  <div
                    className="grey-cards-content-holder"
                    data-aos="fade-left"
                  >
                    <div className="grey-card-text-holder">
                      <div className="grey-card-icon-holder">
                        <img src={card_icon1} className="grey-card-icon" />
                      </div>
                      <div className="card-title-holder">
                        <h2 className="white-title">You own the design</h2>
                      </div>
                      <div className="card-paraghraph-holder">
                        <p className="card-paragraph">
                          Anything you make with Aipperal is completely
                          original. As the creator, you can do anything you want
                          with your designs.
                        </p>
                      </div>
                    </div>
                    <div className="grey-card-image-holder">
                      <img src={card_image} className="grey-card-image" />
                    </div>
                  </div>
                  <div className="background-decor-holder">
                    <img
                      src={imageUnknown2}
                      loading="lazy"
                      sizes="(max-width: 479px) 94vw, (max-width: 767px) 93vw, (max-width: 991px) 94vw, (max-width: 1439px) 95vw, 1294px"
                      alt=""
                      className="background-decor"
                    />
                  </div>
                </div>

                <div className="small-cards-holder">
                  <div
                    id="w-node-fb6e61c5-348f-515a-51ba-509ea31acd9b-e80fff32"
                    className="small-cards"
                  >
                    <div className="small-cards-image-holder">
                      <img src={fast_1} className="small-cards-image" />
                    </div>
                    <div className="small-cards-content-holder">
                      <div className="small-card-text">
                        Cut out the hassle.
                        <br />
                        <br />
                        <span className="grey-text">
                          From design to print in a couple of minutes.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-node-_472e4dc8-aac6-32c2-d384-9ed9dd06f470-e80fff32"
                    data-w-id="472e4dc8-aac6-32c2-d384-9ed9dd06f470"
                    className="small-cards"
                  >
                    <div className="small-cards-image-holder">
                      <img src={text_img} className="small-cards-image" />
                    </div>
                    <div className="small-cards-content-holder">
                      <div className="small-card-text">
                        Create one item, or thousands.
                        <br />
                        <br />
                        <span className="grey-text">
                          {" "}
                          The power is in your hands.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <AboutUs /> */}

        <Footer />
      </div>
    </div>
  );
};

export default Home;
