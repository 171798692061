import React, { useEffect, useState } from "react";
import { Footer, Header, DragDropImage, ImageCaurosel } from "../../Components";
import { countryCode } from "../../utils/constants";
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Image,
  InputNumber,
  Upload,
} from "antd";
import { errorMessage, successMessage, getBase64 } from "../../utils/helpers";
import { getProductMargin } from "../../utils/helpers";
import axios from "axios";
import { GET, POST } from "../../utils/apis";
import { useSelector, useDispatch } from "react-redux";
import apiUrl from "../../Config/api";
import { useLocation } from "react-router-dom";
import { AddUser, createOrderData } from "../../Redux/actions/authActions";
import {
  DownOutlined,
  LeftOutlined,
  RightOutlined,
  UpOutlined,
  UploadOutlined,
} from "@ant-design/icons";

const { Option } = Select;

const CardDetails = (props) => {
  const location = useLocation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [imageAxis, setImageAxis] = useState([
    { id: Math.random(), x: `45%`, y: `45%` },
  ]);
  const [imageAxisInPixels, setImageAxisInPixels] = useState({
    id: Math.random(),
    x: 45,
    y: 45,
  });
  const [color, setColor] = useState("");
  const [productInformation, setProductInformation] = useState({});
  const user = useSelector((state) => state.authReducer.user);
  const createOrderDatas = useSelector(
    (state) => state.authReducer.createOrderData,
  );
  let quantityAndMargin =
    productInformation &&
    productInformation.productPrice * quantity +
      productInformation.shippingPrice;
  let totalPricewithMargin =
    Number(quantityAndMargin) + getProductMargin(Number(quantityAndMargin));
  let cardDetails = localStorage.getItem("cardDetails");
  const detail = JSON.parse(cardDetails);
  const [productImage, setProductImage] = useState(detail && detail.images[0]);
  let country = localStorage.getItem("country");
  const queryParams = new URLSearchParams(location.search);
  const SKU = queryParams.get("sku");
  const status = queryParams.get("status");
  const session = queryParams.get("session");
  const domain = `${window.location.origin}/card-details?sku=${SKU}`;
  let currencyUnit =
    productInformation && productInformation.destinationCountry == "GB"
      ? "£"
      : productInformation && productInformation.destinationCountry == "US"
      ? "$"
      : "A$";
  useEffect(() => {
    if (SKU) {
      getProdcutDetail();
    }
  }, [SKU]);

  useEffect(() => {
    if (SKU) {
      getProdcutDetail();
    }
  }, []);

  const getProdcutDetail = (countryCode) => {
    let code = !!countryCode ? countryCode : country;
    axios
      .get(`${GET.GET_PRODUCTS_WITH_SKU}?code=${code}&sku=${SKU}`)
      .then((res) => {
        const { data } = res;
        if (data.success) {
          setDetailsLoading(false);
          setProductInformation(data.data);
        }
      })
      .catch((e) => {
        console.log(e, "e");
      });
  };

  const onFinish = async (values) => {
    if (values.countryCode.length > 2) {
      return errorMessage("Please Provide a Valid Country Code!");
    }

    if (!selectedImage) {
      return errorMessage("Please Select a Logo");
    }
    setLoading(true);

    // let imagesData = {
    //     userId: user._id,
    //     pixels: JSON.stringify(imageAxisInPixels),
    //     logoURL: selectedImage || 'https://res.cloudinary.com/dpqo4qwyi/image/upload/v1683479342/nf2zlcl3tfzz0mgneoda.png',
    //     url: productImage.imageURL,
    // }

    // let response = await axios.post(POST.IMAGE_ON_IMAGE, imagesData)
    // if (!response.data.success) {
    //     setLoading(false)
    //     return errorMessage(response?.message)
    // }

    let obj = {
      userId: user._id,
      shippingMethod: productInformation && productInformation.shippingMethod,
      logo: selectedImage,
      postalOrZipCode: values.postalOrZipCode,
      countryCode: values.countryCode,
      townOrCity: values.townOrCity,
      copies: values.copies,
      sku: productInformation && productInformation.sku,
      address: values.address,
      name: values.name,
      size: values.size,
      color: values.color,
      price: totalPricewithMargin,
      pixels: JSON.stringify(imageAxisInPixels),
    };
    dispatch(createOrderData(obj));

    const amount = totalPricewithMargin;
    const paymentType = "checkout";
    // If the user is accessing the website locally, redirect them to the local URL; otherwise, redirect them to the deployment URL
    // amount must be in cents
    const amount_in_cents = parseInt(amount * 100);
    const res = await axios.post(`${apiUrl}/pay`, {
      amount_in_cents,
      paymentType,
      domain,
    });
    console.log(res.data);
    const url = res.data.url;

    window.location.href = url;
  };

  const createOrderFun = () => {
    if (status === "Failed") {
      errorMessage("Payment Failed, please try again.");
      dispatch(createOrderData(null));
      localStorage.removeItem("cardDetails");
      localStorage.removeItem("country");
      setTimeout(() => {
        window.location.href = `${window.location.origin}/products`;
      }, 1000);
      return;
    } else if (status === "Passed") {
      if (session.split(",")[0] === "checkout") {
        // Create a new FormData object
        const formData = new FormData();

        // Append the properties of the object to the FormData
        formData.append("userId", createOrderDatas.userId);
        formData.append("shippingMethod", createOrderDatas.shippingMethod);
        formData.append("postalOrZipCode", createOrderDatas.postalOrZipCode);
        formData.append("countryCode", createOrderDatas.countryCode);
        formData.append("townOrCity", createOrderDatas.townOrCity);
        formData.append("copies", createOrderDatas.copies);
        formData.append("sku", createOrderDatas.sku);
        formData.append("address", createOrderDatas.address);
        formData.append("name", createOrderDatas.name);
        formData.append("size", createOrderDatas.size);
        formData.append("color", createOrderDatas.color);
        formData.append("price", createOrderDatas.price);
        formData.append("pixels", createOrderDatas.pixels);
        formData.append("logo", createOrderDatas.logo);

        axios
          .post(POST.CREATE_ORDER, formData)
          .then((res) => {
            const { data } = res;
            console.log("data", data);
            if (data.success) {
              dispatch(AddUser(data.user));
              alert(data?.cloudUrl);
              successMessage("Successfully Created!");
              form.resetFields();
              localStorage.removeItem("image");
              // setLogoUrl(null)
              setSelectedImage(null);
              dispatch(createOrderData(null));
              localStorage.removeItem("cardDetails");
              localStorage.removeItem("country");
              setTimeout(() => {
                window.location.href = `${window.location.origin}/Profile`;
              }, 1000);
            } else {
              console.log("data", data);
              errorMessage(data.message || "Order creating error!");
              dispatch(createOrderData(null));
              localStorage.removeItem("cardDetails");
              localStorage.removeItem("country");
              setTimeout(() => {
                window.location.href = `${window.location.origin}/products`;
              }, 1000);
            }
            setLoading(false);
          })
          .catch((e) => {
            errorMessage("Oops! some thing went wrong!");
            console.log(e, "e");
            setLoading(false);
            form.resetFields();
            dispatch(createOrderData(null));
            localStorage.removeItem("cardDetails");
            localStorage.removeItem("country");
            setTimeout(() => {
              window.location.href = `${window.location.origin}/products`;
            }, 500);
          });
      }
    }
  };

  useEffect(() => {
    createOrderFun();
  }, [status, session]);

  const handleSelect = (event) => {
    setSelectedImage(event);
  };

  const getPrice = (event) => {
    let code = event;
    console.log(code);
    getProdcutDetail(code);
    return null;
  };

  const [xAxis, setXAxis] = useState(45);
  const [yAxis, setYAxis] = useState(45);

  const incremnt = (setState) => {
    setState((prev) => (prev == 90 ? prev : prev + 5));
    handleSpots();
  };
  const decrement = (setState) => {
    setState((prev) => (prev == 0 ? prev : prev - 5));
    handleSpots();
  };

  const handleSpots = () => {
    let obj = {
      id: Math.random(),
      x: `${xAxis}%`,
      y: `${yAxis}%`,
    };
    let pixels = {
      x: xAxis,
      y: yAxis,
    };
    setImageAxisInPixels(pixels);
    setImageAxis([obj]);
  };

  const onChange = async ({ fileList: newFileList }) => {
    let url = await getBase64(
      newFileList[0] && newFileList[newFileList.length - 1].originFileObj,
    );
    setSelectedImage(url);
  };

  const validateQuantity = (rule, value, callback) => {
    if (value === "" || value === undefined) {
      callback("Please input your copies!");
    } else {
      const intValue = parseInt(value, 10);
      if (isNaN(intValue) || intValue <= 0) {
        callback("Quantity must be a positive number!");
      } else {
        callback();
      }
    }
  };

  return (
    <div className="pds_Section">
      <Header />
      {!detailsLoading && !!productInformation ? (
        <>
          <Form
            name="time_related_controls"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            requiredMark={false}
            layout={"vertical"}
            form={form}
          >
            <div
              style={{
                padding: 5,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                flexWrap: "wrap",
              }}
            >
              <div>
                <p style={{ fontSize: 24 }}>USER DETAILS</p>

                <Form.Item
                  label="Name"
                  name="name"
                  initialValue={user && user.name}
                >
                  <Input bordered allowClear defaultValue={user.name} />
                </Form.Item>

                <Form.Item
                  label="Email"
                  name="email"
                  initialValue={user && user.email}
                >
                  <Input bordered allowClear defaultValue={user.email} />
                </Form.Item>
                <Form.Item
                  label="Address"
                  name="address"
                  initialValue={user && user.address}
                  rules={[
                    { required: true, message: "Please input your address!" },
                  ]}
                >
                  <Input bordered allowClear />
                </Form.Item>

                <Form.Item
                  label="Country Code"
                  name="countryCode"
                  showSearch
                  initialValue={country && country}
                  rules={[
                    {
                      required: true,
                      message: "Please input your Country Code!",
                    },
                  ]}
                >
                  <Input disabled bordered allowClear />
                </Form.Item>

                <Form.Item
                  label="Postal Or Zip Code"
                  name="postalOrZipCode"
                  initialValue={user && user.postalOrZipCode}
                  rules={[
                    {
                      required: true,
                      message: "Please input your Postal Or Zip Code!",
                    },
                  ]}
                >
                  <Input bordered allowClear />
                </Form.Item>

                <Form.Item
                  label="Town or City"
                  name="townOrCity"
                  initialValue={user && user.townOrCity}
                  rules={[
                    {
                      required: true,
                      message: "Please input your Town or City!",
                    },
                  ]}
                >
                  <Input bordered allowClear />
                </Form.Item>
              </div>

              <div>
                <p style={{ fontSize: 24 }}>ORDER DETAILS</p>
                <Form.Item label="Item (SKU code)" name="sku">
                  <div>
                    <Input
                      style={{ color: "black" }}
                      bordered
                      allowClear
                      disabled
                      value={
                        productInformation &&
                        productInformation.sku &&
                        productInformation.sku
                      }
                    />
                  </div>
                </Form.Item>
                <Form.Item
                  label="Quantity"
                  name="copies"
                  // rules={[{ required: true, message: 'Please input your copies!' }]}
                  rules={[{ validator: validateQuantity }]}
                >
                  <Input
                    type="number"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    bordered
                    allowClear
                  />
                </Form.Item>
                <Form.Item label="Shipping Method" name="ShippingMethod">
                  <div>
                    <Input
                      style={{ color: "black" }}
                      bordered
                      disabled
                      allowClear
                      value={`${
                        productInformation &&
                        !!productInformation.shippingMethod &&
                        productInformation.shippingMethod
                      } (${currencyUnit && currencyUnit} ${
                        productInformation && productInformation.shippingPrice
                      })`}
                    />
                  </div>
                </Form.Item>

                <Form.Item label="Delivery Time" name="DeliveryTime">
                  <div>
                    <Input
                      allowClear
                      bordered
                      style={{ color: "black" }}
                      disabled
                      value={`${
                        productInformation && productInformation.mimumShipping
                      } to ${
                        productInformation && productInformation.maximumShipping
                      } days`}
                    />
                  </div>
                </Form.Item>

                {productInformation &&
                  productInformation.variations &&
                  productInformation.variations.length > 0 && (
                    <Form.Item
                      label="Color"
                      name="color"
                      rules={[
                        {
                          required: true,
                          message: "Please select color",
                        },
                      ]}
                    >
                      <Select
                        className="selectDropDown"
                        placeholder={"Color"}
                        onChange={(e) => {
                          setColor(e);
                        }}
                      >
                        {productInformation &&
                          productInformation.variations &&
                          productInformation.variations.length > 0 &&
                          Array.from(
                            new Set(
                              productInformation.variations.map((v) => v.color),
                            ),
                          ).map((color, i) => (
                            <Option key={i} value={color}>
                              {color}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  )}

                {productInformation &&
                  productInformation.variations &&
                  productInformation.variations.length > 0 && (
                    <Form.Item
                      label="Size"
                      name="size"
                      rules={[
                        {
                          required: true,
                          message: "Please select Size",
                        },
                      ]}
                    >
                      <Select
                        className="selectDropDown"
                        placeholder={"Size"}
                        key={color}
                      >
                        {productInformation &&
                          productInformation.variations &&
                          Array.from(
                            new Set(
                              productInformation.variations.map((v) => v.size),
                            ),
                          ).map((u, i) => {
                            const matchingVariation =
                              productInformation.variations.find(
                                (variation) => variation.color === color,
                              );

                            if (matchingVariation) {
                              return <Option value={u}>{u}</Option>;
                            }
                          })}
                      </Select>
                    </Form.Item>
                  )}

                <Form.Item label="Logo Image" name="imageLogo">
                  <Select
                    className="selectDropDown"
                    value={selectedImage}
                    onChange={handleSelect}
                  >
                    {user.buildImages &&
                      user.buildImages.length >= 0 &&
                      user.buildImages.map((image, ind) => (
                        <Option key={ind} value={image}>
                          {/* <Image src={image} style={{ width: 100, height: 100 }} /> */}
                          <img
                            src={image}
                            style={{ width: 100, height: 100 }}
                          />
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <b
                  style={{
                    margin: "10px auto",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  OR
                </b>
                <div style={{ width: 360 }}>
                  <Upload
                    className="selectDropDown"
                    showUploadList={false}
                    beforeUpload={() => false}
                    onChange={onChange}
                    multiple={false}
                    accept="image/png, image/jpeg"
                    style={{ width: 360 }}
                  >
                    <Button
                      style={{ width: 360, height: 50, borderRadius: 10 }}
                      icon={<UploadOutlined />}
                    >
                      Please Select Your logo Image
                    </Button>
                  </Upload>
                </div>
              </div>

              <div>
                <p style={{ fontSize: 24 }}>PRICE DETAILS</p>

                <Form.Item label="Total Price" name="price">
                  <div>
                    <Input
                      style={{ color: "black" }}
                      bordered
                      disabled
                      allowClear
                      value={
                        totalPricewithMargin &&
                        Number(totalPricewithMargin).toFixed(2)
                      }
                    />
                  </div>
                </Form.Item>
                {/* {productImage && !!productImage._id ?
                                    <div >
                                        <p onClick={() => {
                                            setProductImage(null)
                                        }} className='cancel-image'>X</p>
                                    </div>
                                    : null} */}

                <DragDropImage
                  setProductImage={setProductImage}
                  productImage={productImage}
                  logoUrl={selectedImage}
                  imgUrl={detail && detail.images}
                  spots={imageAxis}
                />
                <br />
                <br />
                <br />
                <br />
                <br />

                {productImage && !!productImage._id ? (
                  <>
                    <Form.Item
                      name={["spots", "x"]}
                      validateTrigger={["onChange", "onBlur"]}
                      noStyle
                      initialValue={45}
                    >
                      <div
                        style={{
                          width: "100%",
                          margin: "10px auto",
                          marginLeft: 125,
                        }}
                      >
                        <div
                          onClick={() => decrement(setYAxis)}
                          style={{
                            width: 30,
                            height: 30,
                            borderRadius: 30,
                            background: "silver",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: 30,
                          }}
                        >
                          <UpOutlined />
                        </div>

                        <div style={{ display: "flex" }}>
                          <div
                            onClick={() => decrement(setXAxis)}
                            style={{
                              width: 30,
                              height: 30,
                              borderRadius: 30,
                              background: "silver",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <LeftOutlined />
                          </div>
                          <div
                            onClick={() => incremnt(setXAxis)}
                            style={{
                              width: 30,
                              height: 30,
                              borderRadius: 30,
                              background: "silver",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginLeft: 30,
                            }}
                          >
                            <RightOutlined />
                          </div>
                        </div>
                        <div
                          onClick={() => incremnt(setYAxis)}
                          style={{
                            width: 30,
                            height: 30,
                            borderRadius: 30,
                            background: "silver",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: 30,
                          }}
                        >
                          <DownOutlined />
                        </div>
                      </div>
                    </Form.Item>
                    <br />
                    {/* <Form.Item
                                            name={['spots', 'y']}
                                            validateTrigger={['onChange', 'onBlur']}
                                            noStyle
                                            initialValue={45}
                                        >
                                            <InputNumber
                                                width={350}
                                                addonBefore='Y'
                                                onChange={handleSpots}
                                                min={0}
                                                max={90}
                                                precision={0}
                                                step={5}
                                                bordered
                                                allowClear
                                            />
                                        </Form.Item> */}
                    <br />
                    <Form.Item>
                      <Button
                        loading={loading}
                        className="create-order-sub-pay"
                        htmlType="submit"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </>
                ) : null}
              </div>
            </div>
          </Form>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1>Loading...</h1>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default CardDetails;
