import apiUrl from "../Config/api";

const authApi = `${apiUrl}/api/user`;
const getApi = `${apiUrl}/api/get`;
const postApi = `${apiUrl}/api/post`;

const AUTH = {
  LOGIN: `${authApi}/login`,
  SIGNUP: `${authApi}/signup`,
  UPDATEUSER: `${authApi}/updateUser`,
  CHANGEPASSWORD: `${authApi}/changePassword`,
  SOCIALLOGIN: `${authApi}/socialLogin`,
  GET_URL_FROM_CLOUDINARY: `${authApi}/get-url-from-cloudinary`,
};

const GET = {
  PRODUCTS: `${getApi}/get-products`,
  GET_PRODUCTS_WITH_SKU: `${getApi}/get-product-with-sku`,
  GET_MY_ORDERS: `${getApi}/get-all-orders`,
};

const POST = {
  CREATE_ORDER: `${postApi}/create-order`,
  CREATE_ORDER_PAY: `${postApi}/create-order-pay`,
  IMAGE_ON_IMAGE: `${postApi}/images-on-images`,
};

export { AUTH, GET, POST };
