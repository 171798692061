import React from "react";
import ai1 from "../../assets/Images/ai1.webp";
import ai2 from "../../assets/Images/ai2.webp";
import ai3 from "../../assets/Images/ai3.webp";
import ai4 from "../../assets/Images/ai4.webp";
import ai5 from "../../assets/Images/ai5.webp";
import ai6 from "../../assets/Images/ai6.webp";
import ai7 from "../../assets/Images/ai7.webp";
import ai8 from "../../assets/Images/ai8.webp";

const Animation = () => {
  return (
    <div className="infinite-ai-images-holder">
      <div className="infinite-ai-images-container">
        <div className="infinite-ai-images-grid">
          <div className="infinite-ai-image-wrapper">
            <img src={ai1} className="infnite-image" />
            <img src={ai6} className="infnite-image" />
          </div>
          <div className="infinite-ai-image-wrapper _02">
            <img src={ai2} className="infnite-image" />
            <img src={ai7} className="infnite-image" />
          </div>
          <div className="infinite-ai-image-wrapper _03">
            <img src={ai3} className="infnite-image" />
            <img src={ai8} className="infnite-image" />
          </div>
          <div className="infinite-ai-image-wrapper _02">
            <img src={ai4} className="infnite-image" />
          </div>
          <div className="infinite-ai-image-wrapper">
            <img src={ai5} className="infnite-image" />
            <img src={ai6} className="infnite-image" />
          </div>
        </div>
        <div className="infinite-ai-images-grid">
          <div className="infinite-ai-image-wrapper">
            <img src={ai1} className="infnite-image" />
            <img src={ai3} className="infnite-image" />
          </div>
          <div className="infinite-ai-image-wrapper _02">
            <img src={ai2} className="infnite-image" />
            <img src={ai7} className="infnite-image" />
          </div>
          <div className="infinite-ai-image-wrapper _03">
            <img src={ai3} className="infnite-image" />
            <img src={ai8} className="infnite-image" />
          </div>
          <div className="infinite-ai-image-wrapper _02">
            <img src={ai4} className="infnite-image" />
          </div>
          <div className="infinite-ai-image-wrapper">
            <img src={ai5} className="infnite-image" />
            <img src={ai6} className="infnite-image" />
          </div>
        </div>
        <div className="infinite-ai-images-grid">
          <div className="infinite-ai-image-wrapper">
            <img className="infnite-image" />
            <img className="infnite-image" />
          </div>
          <div className="infinite-ai-image-wrapper _02">
            <img className="infnite-image" />
            <img className="infnite-image" />
          </div>
          <div className="infinite-ai-image-wrapper _03">
            <img className="infnite-image" />
            <img className="infnite-image" />
          </div>
          <div className="infinite-ai-image-wrapper _02">
            <img className="infnite-image" />
          </div>
          <div className="infinite-ai-image-wrapper">
            <img className="infnite-image" />
            <img className="infnite-image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Animation;
