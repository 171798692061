import React, { useState, useEffect } from "react";
import { DESCRIPTORS } from "./descriptors/library";

const Dropdowns = ({ onDropdownsChange }) => {
  const [selectedItems, setSelectedItems] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [newItemValue, setNewItemValue] = useState("");
  const [keyToAddItem, setKeyToAddItem] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState({});

  useEffect(() => {
    onDropdownsChange && onDropdownsChange(selectedItems);
  }, [selectedItems, onDropdownsChange]);

  const handleItemClick = (key, item) => {
    setSelectedItems((prevSelectedItems) => {
      const selected = prevSelectedItems[key] || [];
      if (selected.includes(item)) {
        return {
          ...prevSelectedItems,
          [key]: selected.filter((i) => i !== item),
        };
      }
      return {
        ...prevSelectedItems,
        [key]: [...selected, item],
      };
    });
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleAddClick = (key) => {
    setKeyToAddItem(key);
    setShowAddModal(true);
  };

  const handleAddModalSubmit = (e) => {
    e.preventDefault();
    setShowAddModal(false);
    // Add the new value to the dropdown options
  };

  const handleAddModalInputChange = (e) => {
    setNewItemValue(e.target.value);
  };

  const clearSearchValue = () => {
    setSearchValue("");
  };

  const filteredDescriptors = Object.entries(DESCRIPTORS).map(
    ([key, items]) => {
      const filteredItems = items.filter((item) =>
        item.toLowerCase().includes(searchValue.toLowerCase()),
      );
      return { key, items: filteredItems };
    },
  );

  const toggleDropdown = (key) => {
    setIsDropdownOpen((prevIsDropdownOpen) => ({
      ...prevIsDropdownOpen,
      [key]: !prevIsDropdownOpen[key],
    }));
  };

  return (
    <div>
      {filteredDescriptors.map(({ key, items }) => (
        <div key={key}>
          <div className="advanced-header">
            <div
              className="label-wrapper"
              onClick={() => toggleDropdown(key)}
              style={{ display: "flex", alignItems: "center" }}
            >
              <label
                className="advanced-label"
                style={{
                  color: "white",
                  marginRight: "0.5rem",
                  paddingTop: "20px",
                  paddingBottom: "10px",
                }}
              >
                {key}
              </label>
              <span
                style={{ color: "#33363D" }}
                className={`dropdown-arrow${
                  isDropdownOpen[key] ? " open" : ""
                }`}
              >
                {isDropdownOpen[key] ? (
                  <span>&#9660;</span>
                ) : (
                  <span>&#9658;</span>
                )}
              </span>
            </div>
          </div>
          {isDropdownOpen[key] && (
            <>
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  placeholder={`Search ${key}`}
                  value={searchValue}
                  onChange={handleSearchChange}
                  className="text-field-3 form-textfield w-input build_input"
                  maxLength={256}
                  style={{
                    paddingRight: "1.5rem",
                  }}
                />
                {searchValue && (
                  <div
                    onClick={clearSearchValue}
                    style={{
                      position: "absolute",
                      right: "5px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      width: "1rem",
                      height: "1rem",
                      backgroundImage:
                        "url('data:image/svg+xml;utf8,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 24 24%22 fill=%22white%22><path d=%22M19 6.41l-1.41-1.41-5.59 5.59-5.59-5.59-1.41 1.41 5.59 5.59-5.59 5.59 1.41 1.41 5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59z%22/></svg>')",
                      backgroundSize: "cover",
                      backgroundColor: "black",
                      cursor: "pointer",
                    }}
                  />
                )}
              </div>
              <div
                style={{
                  maxHeight: "200px",
                  overflow: "auto",
                  borderRadius: "10px",
                  backgroundColor: "black",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  paddingLeft: "5px",
                }}
              >
                {items.map((item, index) => (
                  <div
                    key={`${item}-${index}`}
                    onClick={() => handleItemClick(key, item)}
                    style={{
                      backgroundColor:
                        selectedItems[key] && selectedItems[key].includes(item)
                          ? "#d6fc52"
                          : "#33363D",
                      color:
                        selectedItems[key] && selectedItems[key].includes(item)
                          ? "black"
                          : "white",
                      padding: "5px",
                    }}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default Dropdowns;
