import * as styles from "./styles";
import * as composition from "./composition";
import * as details from "./details";
import * as lighting_effects from "./lighting_effects";

const DESCRIPTORS = {
  Styles: styles.LIBRARY,
  Effects: lighting_effects.LIBRARY,
  Details: details.LIBRARY,
  Composition: composition.LIBRARY,
};
export { DESCRIPTORS };
