const LIBRARY = [
  "Realism",
  "Impressionism",
  "Expressionism",
  "Cubism",
  "Surrealism",
  "Abstract",
  "Minimalism",
  "Pop Art",
  "Fauvism",
  "Dada",
  "Futurism",
  "Constructivism",
  "Hyperrealism",
  "Photorealism",
  "Conceptual",
  "Fine Art",
  "Street Photography",
  "Portrait Photography",
  "Landscape Photography",
  "Nature Photography",
  "Macro Photography",
  "Astrophotography",
  "Underwater Photography",
  "Sports Photography",
  "Fashion Photography",
  "Food Photography",
  "Documentary Photography",
  "Travel Photography",
  "Journalistic Photography",
  "Candid Photography",
  "Staged Photography",
  "Artistic Photography",
  "Black and White",
  "Sepia",
  "High Dynamic Range (HDR)",
  "Long Exposure",
  "Panorama",
  "Timelapse",
  "Stop Motion",
  "Cinemagraph",
];
export { LIBRARY };
