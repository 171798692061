import React, { useState, useEffect } from "react";
import { sendToApi, handleData, getImageExamples } from "../../Config/api";
import AdditionalOptions from "./AdditionalOptions";
import AdvancedOptions from "./AdvancedOptions";
import size_h from "../../assets/Images/size_horizontal.png";
import size_s from "../../assets/Images/size_square.png";
import size_v from "../../assets/Images/size_vertical.png";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import { useHistory } from "react-router-dom";
import allPaths from "../../Config/paths";
import axios from "axios";
import { AddUser } from "../../Redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { AUTH, POST } from "../../utils/apis";
import {
  requiredMessage,
  inputPlace,
  successMessage,
  errorMessage,
  warningMessage,
} from "../../utils/helpers";
import "./ImageExample.css";
import { scaleDownDimensions } from "../../Config/utils";

const ImageForm = () => {
  const user = useSelector((state) => state.authReducer.user);
  const dispatch = useDispatch();
  const [promt, setPromt] = useState("");
  const [negPromt, setNegPromt] = useState("");
  const [height, setHeight] = useState(512);
  const [width, setWidth] = useState(512);
  const [imageUrl, setImageUrl] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [advancedOptions, setAdvancedOptions] = useState({
    guidanceScale: 14,
    steps: 40,
    seed: 610199607,
    isChecked: false,
  });
  const [additionalOptions, setAdditionalOptions] = useState({
    height: 512,
    width: 512,
    dropdowns: {},
  });
  const history = useHistory();

  const handleAdvancedOptionsChange = (options) => {
    if (
      options.guidanceScale !== advancedOptions.guidanceScale ||
      options.steps !== advancedOptions.steps ||
      options.seed !== advancedOptions.seed ||
      options.isChecked !== advancedOptions.isChecked
    ) {
      setAdvancedOptions(options);
    }
  };
  const handleAdditionalOptionsChange = (options) => {
    if (
      options.height !== additionalOptions.height ||
      options.width !== additionalOptions.width ||
      options.dropdowns !== additionalOptions.dropdowns
    ) {
      setAdditionalOptions(options);
    }
  };

  const handlePresetImageSize = (event, height, width) => {
    let scaleDown = scaleDownDimensions(height, width);
    setHeight(scaleDown.height);
    setWidth(scaleDown.width);
    handleHighlight(event);
    handleAdditionalOptionsChange({
      height: scaleDown.height,
      width: scaleDown.width,
    });
  };

  const handleHighlight = (event) => {
    const images = document.querySelectorAll(".image-preset");
    images.forEach((image) => {
      image.style.transform = "scale(1)";
      image.style.filter = "blur(5px)";
    });
    event.target.style.transform = "scale(1.5)";
    event.target.style.filter = "blur(0px)";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user.topUp <= 0) {
      warningMessage("You have insufficient Credit");
      history.push(allPaths.PROFILE);
      return true;
    }
    setLoading(true);
    const formData = new FormData(e.target);
    const data = {};
    for (let [key, value] of formData.entries()) {
      data[key] = value;
    }
    // Include advancedOptions and dropdowns data
    data.advancedOptions = advancedOptions;
    data.dropdowns = additionalOptions.dropdowns;
    data.imageSize = additionalOptions;

    let responseBody = await handleData(data);
    const imageBase64 = await sendToApi(responseBody);
    if (imageBase64 !== null && imageBase64.includes("data:image/")) {
      setImageUrl(imageBase64);
      let obj = { userId: user._id, topUp: Number(user.topUp) - 1 };
      axios
        .post(AUTH.UPDATEUSER, obj)
        .then((res) => {
          const { data } = res;
          if (data.success) {
            dispatch(AddUser(data.user));
          }
        })
        .catch((e) => {
          console.log("e", e);
        });
    } else {
      errorMessage("Failed to load, please try again");
    }
    setLoading(false);
  };

  const [items, setItems] = useState([]);
  useEffect(() => {
    getImageExamples().then(setItems);
  }, []);
  async function ImageExamples(item) {
    if (item && item.meta) {
      const ItemMeta = item.meta;
      if (ItemMeta.prompt) {
        setPromt(ItemMeta.prompt);
      }
      if (ItemMeta.negativePrompt) {
        setNegPromt(ItemMeta.negativePrompt);
      }
      if (ItemMeta.Size) {
        const [widthVal, heightVal] = ItemMeta.Size.split("x").map(
          (size) => Math.round(size / 8) * 8,
        );
        handleAdditionalOptionsChange(scaleDownDimensions(heightVal, widthVal));
      }
      let opt = {
        guidanceScale: ItemMeta.cfgScale,
        steps: ItemMeta.steps,
        seed: ItemMeta.seed,
        isChecked: false,
      };
      handleAdvancedOptionsChange(opt);
    } else {
      errorMessage("Invalid item or item.meta");
    }
  }

  async function teemillbuy(base64_image) {
    const apiKey = "VKEEoxGBZmcxfJ51nvzAIOgYrZBLm3zBv3EHyPZK";
    // Set the fields to submit. image_url is the only required field for the API request. If you want, you can set the product name, description and price. You can also change the product type and colours using item_code and colours. To find an up-to-date list of available options for these fields, visit this endpoint: https://teemill.com/omnis/v3/product/options/
    const options = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${apiKey}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        image_url: base64_image,
        item_code: "RNA1",
        name: "Aipperal Tee",
        colours: [
          "Black",
          "White",
          "Navy Blue",
          "Red",
          "Athletic Grey",
          "Dark Grey",
          "Bright Blue",
        ],
        description:
          "Check out this awesome Aipperal tee, printed on an organic cotton t-shirt in a renewable energy powered factory.",
      }),
    };

    // Attempt to open a new tab
    var newTab = window.open("about:blank", "_blank");
    // Check if the new tab was blocked by a pop-up blocker
    if (!newTab) {
      warningMessage(
        "Pop-up blocked! Please allow pop-ups for this site and try again.",
      );
      return;
    }
    newTab.document.write(
      "<body style='background-color:#faf9f9;width:100%;height:100%;margin:0;position:relative;'><img src='https://storage.googleapis.com/teemill-dev-image-bucket/doodle2tee_loader.gif' style='position:absolute;top:calc(50% - 100px);left:calc(50% - 100px);'/></body>",
    );

    // Send the API request, and redirect the new tab to the URL that is returned
    fetch("https://teemill.com/omnis/v3/product/create", options)
      .then((response) => response.json())
      .then((response) => (newTab.location.href = response.url))
      .catch((err) => console.error(err));
  }

  return (
    <div>
      <div
        className="div-block"
        style={{
          paddingTop: "10px",
          paddingBottom: "10px",
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        <form
          id="wf-form-Build-Form"
          name="wf-form-Build-Form"
          style={{ flexBasis: "100%" }}
          data-name="Build Form"
          method="post"
          className="form-2"
          onSubmit={handleSubmit}
        >
          <div style={{ flexBasis: "65%", paddingRight: "10px" }}>
            <label htmlFor="promt" style={{ color: "white" }}>
              Generate your idea:
            </label>
            <br />
            <input
              type="text"
              className="text-field-2 form-textfield w-input  build_input "
              name="promt"
              data-name="promt"
              placeholder="Generate your idea"
              id="promt"
              required="*"
              value={promt}
              onChange={(e) => setPromt(e.target.value)}
            />
            <input
              type="text"
              className="text-field-3 form-textfield w-input build_input "
              name="neg_promt"
              data-name="neg_promt"
              placeholder="What to exclude (Optional)"
              id="neg_promt"
              required=""
              value={negPromt}
              onChange={(e) => setNegPromt(e.target.value)}
            />
            <input
              type="submit"
              value={
                "Submit (Credit:" + (user.topUp > 0 ? user.topUp : 0) + ")"
              }
              data-wait="Please wait..."
              className="submit-button button full w-button"
            />
            <br />
            <br />
            {loading ? (
              <div className="lds-loading">
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              imageUrl && (
                <div className="build-page-image">
                  <img
                    src={imageUrl}
                    aria-hidden
                    alt="Generated Image"
                    style={{
                      maxWidth: "300%",
                      maxHeight: "300%",
                    }}
                  />
                  <div className="image-options">
                    <LocalMallIcon
                      color="white"
                      style={{
                        fontSize: "70px",
                        color: "white",
                        cursor: "pointer",
                      }}
                      onClick={() => teemillbuy(imageUrl)}
                    />
                  </div>
                </div>
              )
            )}
            <br />
          </div>
          <div style={{ flexBasis: "35%", paddingLeft: "10px" }}>
            <label style={{ color: "white" }}>Options: </label>

            <br />

            <div
              className="container"
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <img
                className="image-preset"
                src={size_h}
                alt="Size H"
                onClick={(event) => {
                  handlePresetImageSize(event, 640, 1024);
                }}
                style={{ height: `75px`, width: `75px`, marginRight: "25px" }}
              />
              <img
                className="image-preset"
                src={size_s}
                alt="Size S"
                onClick={(event) => {
                  handlePresetImageSize(event, 1024, 1024);
                }}
                style={{ height: `75px`, width: `75px`, marginRight: "10px" }}
              />
              <img
                className="image-preset"
                src={size_v}
                alt="Size V"
                onClick={(event) => {
                  handlePresetImageSize(event, 1024, 640);
                }}
                style={{ height: `75px`, width: `75px`, marginRight: "10px" }}
              />
            </div>

            <br />
            <AdditionalOptions
              onAdditionalOptionsChange={handleAdditionalOptionsChange}
              additionalOptions={additionalOptions}
            />
            <br />
            <AdvancedOptions
              onAdvancedOptionsChange={handleAdvancedOptionsChange}
              advancedOptions={advancedOptions}
            />
          </div>
        </form>
        <div className="w-form-done">
          <div>Thank you! Your submission has been received!</div>
        </div>
        <div className="w-form-fail">
          <div>Oops! Something went wrong while submitting the form.</div>
        </div>
      </div>
      <br />

      <h3 className="title-clean dimmed">
        Looking for inspiration? Choose an image below and customise it:
      </h3>

      <br />
      <div className="image-grid">
        {items
          ? items
              .filter((item) => item.meta !== null)
              .map((item) => (
                <div
                  key={item.id}
                  className="image-container"
                  onClick={() => {
                    ImageExamples(item);
                    window.scrollTo(0, 0);
                  }}
                >
                  <img src={item.url} alt="Example art" className="image" />
                </div>
              ))
          : null}
      </div>
    </div>
  );
};

export default ImageForm;
