import React from "react";
import { Header, Footer } from "../../Components";

const styleContainer = {
  backgroundColor: "black",
  borderRadius: "20px",
  display: "inline-block",
  padding: "2rem",
  margin: "0 auto",
  maxWidth: "800px",
  color: "white",
  lineHeight: "1.5",
};

const styleTitle = {
  color: "white",
  fontSize: "1.5rem",
  marginBottom: "1rem",
};

const styleTexts = {
  color: "white",
  fontSize: "1rem",
  lineHeight: "1.5",
};

function TermsConditions() {
  return (
    <div className="build_Section">
      <Header />
      <div
        className="section"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <div className="container_" style={styleContainer}>
          <h1 style={styleTitle}>Terms and Conditions</h1>
          <label style={styleTexts}>
            By accessing or using our services, you agree to comply with and be
            bound by these terms and conditions.
          </label>

          <h2 style={styleTitle}>Intellectual Property</h2>
          <label style={styleTexts}>
            All intellectual property, including text, graphics, logos, images,
            and software, is owned by us or licensed to us and protected by law.
            Unauthorized use is prohibited.
          </label>

          <h2 style={styleTitle}>Limitation of Liability</h2>
          <label style={styleTexts}>
            To the fullest extent permitted by law, we disclaim all warranties,
            express or implied. We will not be liable for any damages, including
            but not limited to direct, indirect, incidental, punitive, and
            consequential damages arising from or in connection with your use of
            our website or services.
          </label>

          <h2 style={styleTitle}>No Refunds</h2>
          <label style={styleTexts}>
            All transactions are final. Refunds are not provided, except as
            required by law.
          </label>

          <h2 style={styleTitle}>Termination</h2>
          <label style={styleTexts}>
            We reserve the right to terminate or suspend your access to our
            services at any time, without notice, for conduct that violates
            these terms or is harmful to us, other users, or third parties, or
            for any other reason.
          </label>

          <h2 style={styleTitle}>Governing Law</h2>
          <label style={styleTexts}>
            These terms and conditions are governed by the laws of [INSERT YOUR
            STATE/COUNTRY]. Any disputes arising from these terms shall be
            resolved exclusively in [INSERT YOUR STATE/COUNTRY].
          </label>

          <h2 style={styleTitle}>Amendments</h2>
          <label style={styleTexts}>
            We may amend these terms and conditions at any time. Your continued
            use of our services constitutes acceptance of any changes.
          </label>

          <h2 style={styleTitle}>Contact</h2>
          <label style={styleTexts}>
            For any questions, contact us at aipperal.official@gmail.com.
          </label>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TermsConditions;
