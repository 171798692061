import React, { useEffect, useLayoutEffect, useState } from "react";

const DragDropImage = (props) => {
  const { imgUrl, spots, logoUrl, setProductImage, productImage } = props;
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    setTimeout(() => {
      handleResize();
    }, 1000);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [imgUrl, spots]);

  const handleResize = () => {
    const image = document.querySelector(".image img");
    const imageWidth = image && image.clientWidth;

    const circleHeight = imageWidth * 0.1; // 10% of the image width
    spots.forEach((v) => {
      const circle = document.getElementById(v.id);
      if (circle) {
        circle.style.height = circleHeight + "px";
      }
    });
  };

  const SequenceDropZone = (data) => {
    const { spot } = data;
    console.log("spot.x", spot.x);
    let backgroundColor = "";
    const style = {
      position: "absolute",
      // opacity: 0.7,
      left: spot.x || 45,
      top: spot.y || 45,
      width: "10%",
      backgroundColor,
      color: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: "auto",
    };
    return (
      <div
        className="circle"
        id={spot.id}
        style={{ ...style, transition: "all 500ms ease" }}
      >
        {logoUrl && (
          <h1
            style={{ fontSize: window.innerWidth * 0.03 }}
            className="spotNum"
          >
            <img alt="Logo" src={logoUrl} />
          </h1>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="image" style={{ width: "350px", height: 350 }}>
        {/* {productImage && !!productImage._id ? */}

        <div className="image-main-box">
          {imgUrl.length > 0 &&
            imgUrl.map((v, i) => {
              return (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setProductImage(v);
                  }}
                >
                  <img
                    style={{
                      resize: "cover",
                      width: 80,
                      height: 90,
                      paddingTop: 10,
                    }}
                    src={v.imageURL}
                    width={"100%"}
                    alt="Product Image"
                  />
                </div>
              );
            })}
        </div>
        <div style={{ position: "relative" }}>
          <img
            style={{ resize: "cover", marginTop: 10 }}
            src={productImage.imageURL}
            width={"100%"}
            alt="Product Image"
          />
          {spots.map((spot) => (
            <SequenceDropZone key={spot.id} spot={spot} />
          ))}
        </div>
      </div>
    </>
  );
};

export default DragDropImage;
