import { message, notification } from "antd";
import axios from "axios";
import { AUTH } from "./apis";
import allPaths from "../Config/paths";
const PRODUCT_MARGIN = process.env.REACT_APP_PRODUCT_MARGIN || 30;
const SPEED = 1.0;

const successMessage = (desc = "Successfully Complete!") => {
  return message.success(desc, [SPEED]);
};

const infoMessage = (desc = "Successfully Complete!") => {
  return message.info(desc, [SPEED]);
};

const errorMessage = (desc = "Oops Something Went Wrong!") => {
  return message.error(desc, [SPEED]);
};

const warningMessage = (desc = "Warning!") => {
  return message.warning(desc, [SPEED]);
};

const validateEmail = (e) => {
  return String(e)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

const userObject = (profileObj) => {
  return {
    email: profileObj.email,
    name: profileObj.name,
    uid: profileObj.sub,
    imageUrl: profileObj.picture,
  };
};

const googleLogin = (result, history, addUser, dispatch, isSignup) => {
  const obj = userObject(result);
  axios
    .post(AUTH.SOCIALLOGIN, obj)
    .then(async (res) => {
      const { data } = res;
      if (data.success) {
        dispatch(addUser(data.user));
        successMessage(
          isSignup ? "Successfully Sign up" : "Successfully Logged In!",
        );
        return setTimeout(() => {
          history.replace(allPaths.HOME);
        }, 300);
      } else {
        errorMessage(data.message);
        console.log("e mess", data.message);
      }
    })
    .catch((e) => {
      console.log("e", e);

      errorMessage("Oops Something Went Wrong!");
    });
};

const getProductMargin = (price) => {
  const calculateMargin = (price * PRODUCT_MARGIN) / 100;
  return calculateMargin;
};
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export {
  successMessage,
  infoMessage,
  errorMessage,
  warningMessage,
  validateEmail,
  googleLogin,
  getProductMargin,
  getBase64,
};
