import React from "react";
import { Header, Footer } from "../../Components";

const styleContainer = {
  backgroundColor: "black",
  borderRadius: "20px",
  display: "inline-block",
  padding: "2rem",
  margin: "0 auto",
  maxWidth: "800px",
  color: "white",
  lineHeight: "1.5",
};

const styleTitle = {
  color: "white",
  fontSize: "1.5rem",
  marginBottom: "1rem",
};

const styleTexts = {
  color: "white",
  fontSize: "1rem",
  lineHeight: "1.5",
};

function PrivacyPolicy() {
  return (
    <div className="build_Section">
      <Header />
      <div
        className="section"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <div className="container_" style={styleContainer}>
          <h1 style={styleTitle}>Privacy Policy</h1>
          <label style={styleTexts}>
            This Privacy Policy governs the collection, usage, and disclosure of
            your personal information while using our services.
          </label>

          <h2 style={styleTitle}>Information Collected</h2>
          <label style={styleTexts}>
            We collect the personal and non-personal information you provide.
            This may include, but is not limited to, your name, email, and usage
            data.
          </label>

          <h2 style={styleTitle}>Purpose of Information Use</h2>
          <label style={styleTexts}>
            Your information is used to offer our services, enhance user
            experience, and communicate with you for promotional and
            informational purposes.
          </label>

          <h2 style={styleTitle}>Information Disclosure</h2>
          <label style={styleTexts}>
            Your information may be disclosed to third parties assisting in our
            operations or if mandated by law. We may also disclose it if
            necessary for safety or legal reasons.
          </label>

          <h2 style={styleTitle}>Data Security</h2>
          <label style={styleTexts}>
            We implement security measures to safeguard your data, although we
            cannot guarantee absolute security due to the inherent
            vulnerabilities of online transmission and storage.
          </label>

          <h2 style={styleTitle}>Cookies</h2>
          <label style={styleTexts}>
            We use cookies to enhance user experience. You have the option to
            manage cookies via your browser settings.
          </label>

          <h2 style={styleTitle}>Third-Party Websites</h2>
          <label style={styleTexts}>
            We disclaim responsibility for third-party websites linked from our
            platform. We strongly advise reading their privacy policies.
          </label>

          <h2 style={styleTitle}>Children’s Privacy</h2>
          <label style={styleTexts}>
            Our services are not designed for users below the age of 13. We do
            not intentionally collect data from this demographic.
          </label>

          <h2 style={styleTitle}>Policy Updates</h2>
          <label style={styleTexts}>
            We may revise this Privacy Policy. Continued usage of our services
            following any changes implies acceptance.
          </label>

          <h2 style={styleTitle}>Contact</h2>
          <label style={styleTexts}>
            For inquiries about this Privacy Policy, contact us at
            aipperal.official@gmail.com.
          </label>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
