let countryCode = {
  US: "United States",
  GB: "United Kingdom",
  AU: "Australia",
};

let positions = [
  { text: "top right", height: "", width: "" },
  { text: "top center", height: "", width: "" },
  { text: "top left", height: "", width: "" },
  { text: "center right", height: "", width: "" },
  { text: "center", height: "", width: "" },
  { text: "center left", height: "", width: "" },
  { text: "bottom right", height: "", width: "" },
  { text: "bottom center", height: "", width: "" },
  { text: "bottom left", height: "", width: "" },
];

const shippingMethod = ["Budget", "Standard", "Express", "Overnight"];
function findPosition(text) {
  for (let i = 0; i < positions.length; i++) {
    if (positions[i].text === text) {
      return positions[i];
    }
  }
  return null; // Return null if no match is found
}
export { countryCode, positions, shippingMethod, findPosition };
