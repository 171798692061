import React, { useEffect, useState } from "react";
import { Header, Footer, ImageCaurosel } from "../../Components";
import { useSelector } from "react-redux";
import axios from "axios";
import { GET } from "../../utils/apis";
import allPaths from "../../Config/paths";
import { Button, Card, Select } from "antd";
import loadingImg from "../../assets/Images/140955-yellow-loading.gif";
import { countryCode } from "../../utils/constants";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const { Meta } = Card;
const { Option } = Select;

function Products(props) {
  const { history } = props;
  const user = useSelector((state) => state.authReducer.user);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState("US");

  const getProdcuts = (val) => {
    if (user) {
      let code = val ? val : (user && user.countryCode) || "US";
      setLoading(true);
      axios
        .get(`${GET.PRODUCTS}?code=${code}`)
        .then((res) => {
          const { data } = res;
          if (data.success) {
            setProducts(data.data);
          }
          setLoading(false);
          console.log(data);
        })
        .catch((e) => {
          console.log(e, "e");
          setLoading(false);
        });
    }
  };

  useEffect(() => getProdcuts(), []);
  const onCountryChange = (event) => {
    getProdcuts(event);
    setCountry(event);
  };

  if (!user) return <Redirect to={allPaths.LOGIN} />;

  return (
    <div className="pds_Section">
      <Header />
      <div className="section">
        <b
          style={{
            display: "flex",
            justifyContent: "end",
            marginRight: "34px",
          }}
        >
          <Select
            className="selectDropDown"
            showSearch
            placeholder={"Country Code"}
            onChange={onCountryChange}
            autoComplete="off"
            style={{ borderRadius: 10, width: 350, height: 50 }}
            defaultValue={"US"}
          >
            {Object.keys(countryCode).map((key, i) => (
              <Option value={key}>{`${countryCode[key]} (${key})`}</Option>
            ))}
          </Select>
        </b>
        <div className="product-section">
          <div className="product-section-inner">
            {!loading ? (
              products.length > 0 ? (
                products.map((v, i) => {
                  return (
                    <div
                      className="product-card-div"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        history.push(`${allPaths.CARDDETAILS}?sku=${v.sku}`, {
                          cardDetails: v,
                          country,
                        });
                        localStorage.setItem("cardDetails", JSON.stringify(v));
                        localStorage.setItem("country", country);
                      }}
                    >
                      <div className="product-img-main-div">
                        <ImageCaurosel imageUrl={v.images} />
                      </div>
                      <span className="product-name">{v.name}</span>
                      <span style={{ width: "100%" }}>
                        <span className="product-brand-chips">{v.sku}</span>
                      </span>
                    </div>
                  );
                })
              ) : null
            ) : (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginTop: "20px",
                }}
              >
                <img
                  src={loadingImg}
                  style={{ height: "180px", width: "180px" }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Products;
