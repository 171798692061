const LIBRARY = [
  "Ambient Lighting",
  "Natural Lighting",
  "Diffused Lighting",
  "Hard Lighting",
  "Soft Lighting",
  "Spot Lighting",
  "Flood Lighting",
  "Key Lighting",
  "Fill Lighting",
  "Back Lighting",
  "Rim Lighting",
  "Hair Lighting",
  "Facial Lighting",
  "Kicker Lighting",
  "Overhead Lighting",
  "Sidelight",
  "Top Light",
  "Chin Light",
  "Butterfly Lighting",
  "Loop Lighting",
  "Rembrandt Lighting",
  "Split Lighting",
  "Short Lighting",
  "Broad Lighting",
  "Narrow Lighting",
  "Gobo Lighting",
  "Bounced Lighting",
  "Silhouette Lighting",
  "High-Key Lighting",
  "Low-Key Lighting",
  "Dramatic Lighting",
  "Mood Lighting",
  "Colored Lighting",
  "Gelled Lighting",
  "Strobe Lighting",
  "LED Lighting",
  "Fluorescent Lighting",
  "Incandescent Lighting",
  "Continuous Lighting",
];
export { LIBRARY };
