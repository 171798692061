const HOME = "/";
const BUILD = "/build";
const PRICING = "/pricing";
const PROFILE = "/Profile";
const SIGN_UP = "/register";
const LOGIN = "/login";
const RESETPASSWORD = "/resesetpassword";
const UPDATEPASSWORD = "/updatepassword";
const TERMSCONDITIONS = "/termsconditions";
const PRIVACYPOLICY = "/privacypolicy";
const PRODUCTS = "/products";
const CARDDETAILS = "/card-details";

export default {
  HOME,
  BUILD,
  PRICING,
  SIGN_UP,
  LOGIN,
  PROFILE,
  RESETPASSWORD,
  UPDATEPASSWORD,
  TERMSCONDITIONS,
  PRIVACYPOLICY,
  PRODUCTS,
  CARDDETAILS,
};
