import { LOGIN_USER, REMOVE_USER, ADD_USER, CREATE_ORDER_DATA } from "../types";
const initialState = {
  users: [],
  createOrderData: {},
};

const reducer = (state = initialState, action) => {
  // switch (action.type) {
  //     case LOGIN_USER: {
  //         return { ...state, user: action.user }
  //     }
  //     case REMOVE_USER: {
  //         return state = {}
  //     }
  //     default: {
  //         return state
  //     }
  // console.log(state, 'reduces')

  switch (action.type) {
    case ADD_USER:
      return {
        ...state,
        user: action.user,
      };
    case CREATE_ORDER_DATA:
      return {
        ...state,
        createOrderData: action.data,
      };
    case REMOVE_USER: {
      return (state = {});
    }
    default:
      return state;
  }
};

export default reducer;
