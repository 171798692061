import { Image } from "antd";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";

const ImageCaurosel = ({ imageUrl }) => {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % imageUrl.length);
    }, 5000);

    return () => clearInterval(timer);
  }, []);
  return (
    <>
      {!!imageUrl && imageUrl.length && (
        <div>
          <img
            /* style={{ width: 500, height: 400 }} */ src={
              imageUrl[currentImage].imageURL
            }
            alt="Carousel Image"
          />
        </div>
      )}
    </>
  );
};

export default ImageCaurosel;
