const LIBRARY = [
  "Rule of Thirds",
  "Golden Ratio",
  "Diagonal",
  "S-Curve",
  "Leading Lines",
  "Frame within a Frame",
  "Symmetry",
  "Asymmetry",
  "Radial",
  "Triangles",
  "Viewpoint",
  "Background",
  "Foreground",
  "Depth of Field",
  "Perspective",
  "Panorama",
  "Overlapping",
  "Filling the Frame",
  "Cropping",
  "Negative Space",
  "Simplicity",
  "Complexity",
  "Balance",
  "Contrast",
  "Color",
  "Movement",
  "Emphasis",
  "Pattern",
  "Texture",
  "Form",
  "Mass",
  "Macro",
  "Proportion",
  "Variety",
  "Harmony",
  "Rhythm",
  "Dominance",
  "Subordination",
];
export { LIBRARY };
