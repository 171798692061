import React from "react";
import axios from "axios";
import apiUrl from "../../Config/api";

export default class CheckoutForm extends React.Component {
  handleSubmit = async (event) => {
    event.preventDefault();
    const { amount, paymentType } = this.props;
    // amount must be in cents
    const amount_in_cents = parseInt(amount * 100);

    // If the user is accessing the website locally, redirect them to the local URL; otherwise, redirect them to the deployment URL
    const domain = window.location.origin;
    console.log({ amount_in_cents, paymentType });
    const res = await axios.post(apiUrl + "/pay", {
      amount_in_cents,
      paymentType,
      domain,
    });
    console.log(res.data);
    const url = res.data.url;

    window.location.href = url;
  };

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <button className="btn-pay save_and_cancel savechanges">
            Buy Now
          </button>
        </form>
      </div>
    );
  }
}
