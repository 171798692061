import React, { useState, useEffect } from "react";
import Dropdowns from "./BuilderTool";

const AdditionalOptions = ({
  onAdditionalOptionsChange,
  additionalOptions,
}) => {
  const [height, setHeight] = useState(additionalOptions.height);
  const [width, setWidth] = useState(additionalOptions.width);
  const [previewSrc, setPreviewSrc] = useState(null);
  const [dropdowns, setDropdowns] = useState(additionalOptions.dropdowns);
  const [isAdditionalOpen, setIsAdditionalOpen] = useState(false);

  const handleDropdownsChange = (selectedItems) => {
    setDropdowns(selectedItems);
  };

  useEffect(() => {
    setHeight(additionalOptions.height);
    setWidth(additionalOptions.width);
    setDropdowns(additionalOptions.dropdowns);
  }, [additionalOptions]);

  useEffect(() => {
    if (
      height !== additionalOptions.height ||
      width !== additionalOptions.width ||
      dropdowns !== additionalOptions.dropdowns
    ) {
      onAdditionalOptionsChange({ height, width, dropdowns });
    }
  }, [height, width, dropdowns]);

  useEffect(() => {
    // Create a new canvas element to draw the shape on
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;

    // Draw the shape on the canvas
    const context = canvas.getContext("2d");
    context.fillStyle = "white";
    context.fillRect(0, 0, width, height);
    context.fillStyle = "gray";
    context.fillRect(0, 0, width / 2, height / 2);
    context.fillRect(width / 2, height / 2, width / 2, height / 2);

    // Convert the canvas to a data URL and set it as the preview source
    const dataUrl = canvas.toDataURL();
    setPreviewSrc(dataUrl);
  }, [height, width]);

  const toggleAdditional = () => {
    setIsAdditionalOpen(!isAdditionalOpen);
  };

  return (
    <div>
      <div className="advanced-header">
        <div
          className="label-wrapper"
          onClick={toggleAdditional}
          style={{ display: "flex", alignItems: "center" }}
        >
          <label
            className="advanced-label"
            style={{
              color: "white",
              marginRight: "0.5rem",
              paddingTop: "20px",
              paddingBottom: "10px",
            }}
          >
            Additional Options
          </label>
          <span
            className={`arrow-icon${isAdditionalOpen ? " open" : ""}`}
            style={{ color: "#33363D" }}
          >
            {isAdditionalOpen ? <span>&#9660;</span> : <span>&#9658;</span>}
          </span>
        </div>
      </div>

      {isAdditionalOpen && (
        <div>
          <label htmlFor="height" style={{ color: "white" }}>
            Height: {height}
          </label>
          <input
            type="range"
            id="height"
            name="height"
            min="64"
            max="512"
            step="8"
            value={height}
            onChange={(e) => setHeight(Number(e.target.value))}
          />
          <br />
          <label htmlFor="width" style={{ color: "white" }}>
            Width: {width}
          </label>
          <input
            type="range"
            id="width"
            name="width"
            min="64"
            max="512"
            step="8"
            value={width}
            onChange={(e) => setWidth(Number(e.target.value))}
          />
          <br />
          {previewSrc && (
            <img
              src={previewSrc}
              alt="Shape preview"
              width={Math.min(width, 500)}
              height={Math.min(height, 500)}
            />
          )}
          <br />
          <Dropdowns onDropdownsChange={handleDropdownsChange} />
        </div>
      )}
    </div>
  );
};

export default AdditionalOptions;
