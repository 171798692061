import React, { useState, useEffect } from "react";

const AdvancedOptions = ({ onAdvancedOptionsChange, advancedOptions }) => {
  const [guidanceScale, setGuidanceScale] = useState(
    advancedOptions.guidanceScale,
  );
  const [steps, setSteps] = useState(advancedOptions.steps);
  const [seed, setSeed] = useState(advancedOptions.seed);
  const [isChecked, setIsChecked] = useState(advancedOptions.isChecked);
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const toggleAdvanced = () => {
    setIsAdvancedOpen(!isAdvancedOpen);
  };

  useEffect(() => {
    setGuidanceScale(advancedOptions.guidanceScale);
    setSteps(advancedOptions.steps);
    setSeed(advancedOptions.seed);
    setIsChecked(advancedOptions.isChecked);
  }, [advancedOptions]);

  const handleGuidanceScaleChange = (e) => {
    const value = Number(e.target.value);
    setGuidanceScale(value);
    onAdvancedOptionsChange &&
      onAdvancedOptionsChange({ ...advancedOptions, guidanceScale: value });
  };

  const handleStepsChange = (e) => {
    const value = Number(e.target.value);
    setSteps(value);
    onAdvancedOptionsChange &&
      onAdvancedOptionsChange({ ...advancedOptions, steps: value });
  };

  const handleSeedChange = (e) => {
    const value = e.target.value;
    setSeed(value);
    onAdvancedOptionsChange &&
      onAdvancedOptionsChange({ ...advancedOptions, seed: value });
  };

  return (
    <div>
      <div className="advanced-header">
        <div
          className="label-wrapper"
          onClick={toggleAdvanced}
          style={{ display: "flex", alignItems: "center" }}
        >
          <label
            className="advanced-label"
            style={{ color: "white", marginRight: "0.5rem" }}
          >
            Advanced (Optional)
          </label>
          <span
            className={`arrow-icon${isAdvancedOpen ? " open" : ""}`}
            style={{ color: "#33363D" }}
          >
            {isAdvancedOpen ? <span>&#9660;</span> : <span>&#9658;</span>}
          </span>
        </div>
      </div>

      {isAdvancedOpen && (
        <div>
          <div style={{ display: "flex" }}>
            <input
              type="checkbox"
              id="checkbox"
              name="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="myCheckbox" style={{ color: "white" }}>
              {" "}
              Prefix styling tokens automatically ()
            </label>
          </div>
          <br />
          <label htmlFor="guidanceScale" style={{ color: "white" }}>
            Guidance Scale: {guidanceScale}
          </label>
          <input
            type="range"
            id="guidanceScale"
            name="guidanceScale"
            min="0"
            max="50"
            step="0.5"
            value={guidanceScale}
            onChange={handleGuidanceScaleChange}
          />
          <br />
          <label htmlFor="steps" style={{ color: "white" }}>
            Steps: {steps}
          </label>
          <input
            type="range"
            id="steps"
            name="steps"
            min="0"
            max="50"
            step="1"
            value={steps}
            onChange={handleStepsChange}
          />
          <br />
          <label htmlFor="seed" style={{ color: "white" }}>
            Seed (0 = random):
          </label>
          <input
            id="seed"
            name="seed"
            value={seed}
            onChange={handleSeedChange}
            className="text-field-3 form-textfield w-input  build_input "
          />
        </div>
      )}
    </div>
  );
};

export default AdvancedOptions;
