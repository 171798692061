const LIBRARY = [
  "Perfect Proportions",
  "Likeness",
  "Expression",
  "Emotion",
  "Pose",
  "Lighting",
  "Background",
  "Composition",
  "Color",
  "Texture",
  "Form",
  "Space",
  "Value",
  "Proportion",
  "Perspective",
  "Movement",
  "Expression",
  "Emotion",
  "Symbolism",
  "Narrative",
  "Representation",
  "Realism",
  "Surface treatment",
  "Medium",
  "Tools",
  "Technique",
  "Imagery",
  "Iconography",
  "Style",
  "Super-detailed",
  "Ultra-realistic",
  "Studio Quality",
  "8k/HD resolution",
  "High Contrast",
  "Realistic Shadows",
  "Smooth Rendering",
  "Sharp Focus",
];
export { LIBRARY };
