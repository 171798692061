import React, { useEffect } from 'react';

const GoogleTag = () => {
  useEffect(() => {
    // Load the gtag.js script
    const script1 = document.createElement('script');
    script1.async = true;
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-SL5CGQ0TN2";
    document.head.appendChild(script1);

    // After the script is loaded, initialize gtag
    script1.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-SL5CGQ0TN2');
    };
  }, []);

  return null;
};

export default GoogleTag;
