import React, { useEffect } from "react";
import allPaths from "../../Config/paths";
import { useHistory } from "react-router-dom";
import jsonp from "jsonp";

const Footer = () => {
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const url =
      "https://gmail.us21.list-manage.com/subscribe/post-json?u=b98cca37d6369cfa31ec27c77&amp;id=f98c55ae35&amp;f_id=00cfade1f0";
    jsonp(`${url}&EMAIL=${email}`, { param: "c" }, (_, data) => {
      const { msg, result } = data;
      // do something with response
      alert(msg);
    });
  };

  return (
    <div>
      <br />
      <div className="footer">
        <div className="container footer-container">
          <div className="footer-holder">
            <div className="footer-brand-holder">
              <div
                id="w-node-eec48bb4-7097-222d-1919-0fb3ed1909af-0a144c54"
                className="footer-link-wrapper"
              >
                <div className="footer-brand-container">
                  <div className="footer-title">
                    Aipperal is where Artificial Intelligence meets apparel.
                  </div>
                  <div className="small-paragraph">
                    We want your experience to be the best it can be, find our
                    legal information here.
                  </div>
                </div>
                <div className="footer-link-holder">
                  <div className="footer-link-container">
                    <a
                      onClick={() => history.push(allPaths.TERMSCONDITIONS)}
                      className="footer-link w-inline-block"
                    >
                      <div>Terms & Conditions</div>
                    </a>
                    <a
                      onClick={() => history.push(allPaths.PRIVACYPOLICY)}
                      className="footer-link w-inline-block"
                    >
                      <div>Privacy Policy</div>
                    </a>
                    <a href="#About" className="footer-link w-inline-block">
                      <div>About</div>
                    </a>
                    <a
                      href="mailto:aipperal.feedback@gmail.com?subject=Feedback"
                      className="footer-link w-inline-block"
                    >
                      <div>Feedback</div>
                    </a>
                  </div>
                  <div className="footer-link-container"></div>
                </div>
              </div>
              <div
                id="w-node-_593c6d01-8c32-d2cc-ddf9-6693ffc0aaba-0a144c54"
                className="footer-form-holder"
              >
                <div className="form-block w-form">
                  <form
                    id="Newsletter-Form"
                    name="wf-form-Newsletter-form"
                    data-name="Newsletter form"
                    method="get"
                    className="form"
                    onSubmit={handleSubmit}
                  >
                    <div className="form-title-holder">
                      <div className="footer-title">
                        Join our newsletter to stay up to date on features and
                        releases.
                      </div>
                    </div>
                    <div className="text-field-holder">
                      <input
                        type="email"
                        className="text-field w-input"
                        maxLength={256}
                        name="email"
                        placeholder="Email"
                        id="mce-EMAIL"
                        required
                      />
                      <input
                        type="submit"
                        defaultValue="Submit"
                        data-wait="Please wait..."
                        className="button form-button w-button"
                      />
                    </div>
                    <div className="form-title-holder">
                      <div className="small-paragraph">
                        By subscribing you agree to our privacy policy and
                        provide consent to receive updates from our community.
                      </div>
                    </div>
                  </form>

                  <div className="success-message w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>
                      Oops! Something went wrong while submitting the form.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
