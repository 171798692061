export const scaleDownDimensions = (height, width) => {
  const maxHeight = 512;
  const maxWidth = 512;
  let scaledHeight = height;
  let scaledWidth = width;

  const aspectRatio = width / height;

  if (height > maxHeight || width > maxWidth) {
    if (height / maxHeight > width / maxWidth) {
      // Height is the constraining factor
      scaledHeight = maxHeight;
      scaledWidth = Math.round(scaledHeight * aspectRatio);
    } else {
      // Width is the constraining factor
      scaledWidth = maxWidth;
      scaledHeight = Math.round(scaledWidth / aspectRatio);
    }

    // Make dimensions divisible by 8
    scaledWidth = Math.round(scaledWidth / 8) * 8;
    scaledHeight = Math.round(scaledHeight / 8) * 8;
  }

  return {
    height: scaledHeight,
    width: scaledWidth,
  };
};
