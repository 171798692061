import React from "react";
import "./App.css";
import "antd/dist/antd.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import UserAccount from '../src/Screens/UserAccount/UserAccount'
import { Routing } from "./Config/routes";
//import 'animate.css';

const App = () => {
  return (
    <div>
      <Routing />
    </div>
  );
};

export default App;
