// Utility Function for Cleaning Results
function cleanResults(dataItems) {
  const forbiddenWords = [
    "naked",
    "nude",
    "breasts",
    "cleavage",
    "pregnant",
    "shirtlift",
    "nsfw",
  ];
  return dataItems.filter((item) => {
    return (
      item.meta &&
      item.meta.prompt &&
      !forbiddenWords.some((keyword) =>
        item.meta.prompt.toLowerCase().includes(keyword),
      )
    );
  });
}

// Fetch Image Examples
export async function getImageExamples() {
  try {
    const response = await fetch(
      "https://civitai.com/api/v1/images?tags=617&sort=Most%20Reactions&period=Month",
      {
        method: "GET",
        headers: {
          "Access-Control-Allow-Credentials": "true",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      },
    );

    if (!response.ok) {
      throw new Error("Failed to fetch image examples");
    }

    const data = await response.json();
    const cleanedResults = cleanResults(data.items);
    return cleanedResults;
  } catch (error) {
    console.log(error);
    return null;
  }
}

const TOKEN =
  "eeawZrbnnIbnauUOuamEWEXprMdWyvzdyzFWvmhzGoMNtxNBOADScdjfdVPJBwcuwFglIkOUhDURLYVYgLRkcTtoKceTWYpXmFVQVigYUmtlpaGofKjtnZDXMjgVXQkQ";
const test = process.env.HF_TOKEN;

export async function sendToApi(responseBody) {
  console.log("sending data:", test);

  try {
    const response = await fetch(
      "https://elsdigk263ihw45o.us-east-1.aws.endpoints.huggingface.cloud/",
      {
        method: "POST",
        headers: {
          // 'Access-Control-Allow-Credentials': 'true',
          // 'Access-Control-Allow-Origin': "*",
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(responseBody),
      },
    );

    if (response.ok) {
      const imageBase64Str = await response.json();
      return "data:image/png;base64," + imageBase64Str;
    } else {
      console.log(`Request failed with status ${response.status}`);
      return "Request failed.";
    }
  } catch (error) {
    console.log(error);
    return "retry.";
  }
}

export async function handleData(data) {
  const default_neg_prompt =
    "UnrealisticDream, FastNegativeEmbedding, (bad_prompt_version2:0.8), bad-artist, Watermark, bad artist, helmet, blur, blurry, text, b&w, 3d, bad art, poorly drawn, disfigured, deformed, extra limbs, ugly hands, extra fingers, canvas frame, cartoon, 3d, ((disfigured)), ((bad art)), ((deformed)),((extra limbs)),((close up)),((b&w)), weird colors, blurry, (((duplicate))), ((morbid)), ((mutilated)), [out of frame], extra fingers, mutated hands, ((poorly drawn hands)), ((poorly drawn face)), (((mutation))), (((deformed))), ((ugly)), blurry, ((bad anatomy)), (((bad proportions))), ((extra limbs)), cloned face, (((disfigured))), out of frame, ugly, extra limbs, (bad anatomy), gross proportions, (malformed limbs), ((missing arms)), ((missing legs)), (((extra arms))), (((extra legs))), mutated hands, (fused fingers), (too many fingers), (((long neck))), Photoshop, video game, ugly, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, out of frame, mutation, mutated, extra limbs, extra legs, extra arms, disfigured, deformed, cross-eye, body out of frame, blurry, bad art, bad anatomy, 3d render, border";
  let responseBody = {
    inputs: stringBuilder(data["promt"], data["dropdowns"]),
    parameters: {
      negative_prompt: data["neg_promt"]
        ? data["neg_promt"]
        : default_neg_prompt,
      guidance_scale: parseFloat(data["advancedOptions"]["guidanceScale"]),
      num_inference_steps: parseFloat(data["advancedOptions"]["steps"]),
      width: parseFloat(data.imageSize["width"]),
      height: parseFloat(data.imageSize["height"]),
      generator: parseFloat(data["advancedOptions"]["seed"]),
    },
  };
  return responseBody;
}

function stringBuilder(prompt, dropdownDictValues) {
  const defaultValues =
    ", ultra-detailed beautiful panting, HQ, octane render, unreal engine 5, 8K, atmospheric lighting, intricate, detailed, trending on artstation, artstation HQ, by Renato muccillo and Andreas Rocha";
  let dropdownValues = "";
  if (
    dropdownDictValues &&
    typeof dropdownDictValues === "object" &&
    Object.keys(dropdownDictValues).length > 0
  ) {
    for (const [key, value] of Object.entries(dropdownDictValues)) {
      console.log("iter key", key, value);
      let sentence = `, with the ${key} of ${value.join(", ")}`;
      dropdownValues += sentence;
    }
    dropdownValues += ", ";
  } else {
    console.log("No valid dropdownDictValues to iterate over.");
  }
  let allValues = prompt + ", " + dropdownValues + defaultValues;
  let allValuesArray = allValues.split(", ");
  let uniqueValuesArray = Array.from(new Set(allValuesArray));
  return uniqueValuesArray.join(", ");
}

let apiUrl;

if (process.env.NODE_ENV === "development") {
  apiUrl = `http://localhost:3001`;
} else {
  apiUrl = `https://aipperal-backend.herokuapp.com`;
}
export default apiUrl;
