import allPaths from "./paths";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {} from "../Components";
import {
  Home,
  Build,
  Pricing,
  Signup,
  Login,
  UserAccount,
  ResetPassword,
  UpdatePassword,
  PrivacyPolicy,
  TermsConditions,
  Products,
  CardDetails,
} from "../Screens";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Result, Button } from "antd";

const Page404 = (props) => {
  const { history } = props;
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button
          type="primary"
          className="form-button"
          onClick={() => history.push("/")}
        >
          Back Home
        </Button>
      }
    />
  );
};
const Routing = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route path={allPaths.HOME} exact component={Home} />
          <Route path={allPaths.BUILD} exact component={Build} />
          <Route path={allPaths.PRODUCTS} exact component={Products} />
          <Route path={allPaths.PRICING} exact component={Pricing} />
          <Route path={allPaths.PROFILE} exact component={UserAccount} />
          <Route path={allPaths.SIGN_UP} exact component={Signup} />
          <Route path={allPaths.LOGIN} exact component={Login} />
          <Route
            path={allPaths.RESETPASSWORD}
            exact
            component={ResetPassword}
          />
          <Route
            path={allPaths.UPDATEPASSWORD}
            exact
            component={UpdatePassword}
          />
          <Route
            path={allPaths.PRIVACYPOLICY}
            exact
            component={PrivacyPolicy}
          />
          <Route
            path={allPaths.TERMSCONDITIONS}
            exact
            component={TermsConditions}
          />
          <Route path={allPaths.CARDDETAILS} exact component={CardDetails} />

          <Route path="/:page404" exact component={Page404} />
          <Route path="/:page404/:token" exact component={Page404} />
          <Route path="convert-image/:page404" exact component={Page404} />
        </Switch>
      </Router>
    </>
  );
};
export { Routing };
