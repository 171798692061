import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe(
  "pk_test_51MfmgyDVXhtZEs4yRjzMP8hfEk5ftvUA3XuoolTgZgPdPSExckOHMUrEovPYC4aK6TR6rDel4TMFhrCyiRdPIGFH00OQ1LqRdB",
);

const Stripe = ({ amount, paymentType }) => {
  console.log("Here from stripe:", paymentType, amount);
  return (
    <div className="App">
      <div className="product">
        <div>
          <Elements stripe={stripePromise}>
            <CheckoutForm amount={amount} paymentType={paymentType} />
          </Elements>
        </div>
      </div>
    </div>
  );
};

export default Stripe;
